<template>
  <transition name="fade">
    <div
      v-if="isLoading"
      class="d-flex flex-column justify-content-center align-items-center">
      <moon-loader color="#F26000"></moon-loader>
      <p class="orange-small-label mt-2">Aan het laden...</p>
    </div>

    <div class="m-5" v-if="testAnswers && !isLoading">
      <div class="d-flex justify-content-between align-items-center visibility-bar">
        <div class="d-flex align-items-center">
          <div
            class="availability-indicator"
            :class="{
              'is-available': testAnswers.data.isVisibleForUser,
              'is-unavailable': !testAnswers.data.isVisibleForUser
              }"></div>
          <span class="m-0 d-none d-md-inline">Beschikbaar voor kandidaat.</span>
        </div>

        <button
          class="orange-button"
          v-if="!testAnswers.data.isVisibleForUser"
          @click="changeAvailability(true)">
          Maak nu beschikbaar
        </button>
        <button
          class="orange-button"
          v-if="testAnswers.data.isVisibleForUser"
          @click="changeAvailability(false)">
          Maak onbeschikbaar
        </button>
      </div>

      <button class="no-border blue-card w-75" @click="downloadResults()">
        Download jouw resultaten!
      </button>
    </div>
  </transition>
</template>

<script>

import MoonLoader from 'vue-spinner/src/MoonLoader.vue';
import { mapGetters } from 'vuex';

import { FileAPI, AnswerAPI } from '../../../../../../Shared/index';

export default {
  name: 'TestAnswers',
  props: ['testIdentifier'],
  data: () => {
    return {
      testAnswers: null,
      isLoading: false,
    };
  },
  methods: {
    downloadResults() {
      this.isLoading = true;

      const testName = this.testIdentifier.replace('_test_answers', '');

      FileAPI.listFiles(`resultaten-pdfs/${this.currentUser.uid}/${testName}`)
        .then((results) => {
          results.items.forEach((itemRef) => {
            this.downloadFile({
              name: itemRef.name,
              path: `resultaten-pdfs/${this.currentUser.uid}/${testName}/${itemRef.name}`,
            });
          });
          this.isLoading = false;
        })
        .catch((error) => {
          console.log('Error', error);
          this.isLoading = false;
        });
    },
    downloadFile(file) {
      FileAPI.downloadFile(file.path)
        .then((url) => {
          const link = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          link.download = 'resultaat.pdf';
          link.dispatchEvent(new MouseEvent('click'));
          this.isLoading = false;
        })
        .catch((error) => {
          console.log('Error', error);
          this.isLoading = false;
        });
    },
    changeAvailability(newAvailability) {
      const { testIdentifier } = this;
      this.$store.commit('answers/CHANGE_ANSWER_STATUS', {
        testIdentifier,
        newAvailability,
      });
      AnswerAPI.updateQuestionStatus(this.testAnswers.docId, newAvailability);
      this.correctTestAnswers = this.$store.getters['answers/allAnswers'].get(this.testIdentifier);
    },
  },
  mounted() {
    this.testAnswers = this.$store.getters['answers/allAnswers'].get(this.testIdentifier);
  },
  computed: {
    ...mapGetters({
      currentUser: 'answers/user',
    }),
  },
  components: {
    MoonLoader,
  },
};
</script>
