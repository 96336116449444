<template>
  <div class="m-5" v-if="testAnswers">
    <div class="d-flex justify-content-between align-items-center visibility-bar">
      <div class="d-flex align-items-center">
        <div
          class="availability-indicator"
          :class="{
            'is-available': testAnswers.data.isVisibleForUser,
            'is-unavailable': !testAnswers.data.isVisibleForUser
            }"></div>
        <span class="m-0 d-none d-md-inline">Beschikbaar voor kandidaat.</span>
      </div>

      <button
        class="orange-button"
        v-if="!testAnswers.data.isVisibleForUser"
        @click="changeAvailability(true)">
        Maak nu beschikbaar
      </button>
      <button
        class="orange-button"
        v-if="testAnswers.data.isVisibleForUser"
        @click="changeAvailability(false)">
        Maak onbeschikbaar
      </button>
    </div>

    <div class="w-100 mt-2 d-flex justify-content-center">
      <button
        v-if="!pdfLoading"
        class="blue-button w-100 mt-2"
        @click="generateReport()">
        Downloaden (PDF)
      </button>
      <moon-loader v-if="pdfLoading" color="#F26000"></moon-loader>
    </div>

    <h5 class="mt-4">Antwoorden</h5>
    <IdentiteitCardsResults
      :results="allQuestionsAndAnswers"
      class="w-100"/>

    <h5 class="mt-4">Alle antwoorden op een rij</h5>
    <IdentiteitAnswerRow
      v-for="qAndA in allQuestionsAndAnswers"
      :key="qAndA.question.id"
      :questionAndAnswer="qAndA"/>

    <div
      class="w-100 mt-2 d-flex justify-content-center"
      v-if="permissions.isSuperuser">
       <button
        class="red-button w-100 mt-2"
        @click="deleteResult()">
        Verwijderen
      </button>
    </div>
  </div>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue';

import IdentiteitAnswerRow from '@/components/identiteitDetails/IdentiteitAnswerRow.vue';
import IdentiteitCardsResults from '@/components/identiteitDetails/IdentiteitCardsResults.vue';

import { mapGetters } from 'vuex';

import {
  QuestionAPI, AnswerAPI, PDFGeneration, TestTranslator,
} from '../../../../../../Shared/index';

export default {
  name: 'IdentiteitAnswers',
  props: ['testIdentifier'],
  data: () => {
    return {
      allQuestionsAndAnswers: null,
      testAnswers: null,
      pdfLoading: false,
    };
  },
  methods: {
    generateReport() {
      this.pdfLoading = true;
      PDFGeneration.generateIdenteit(this.allQuestionsAndAnswers, `${this.currentUser.firstName} ${this.currentUser.lastName}`)
        .then(() => {
          this.pdfLoading = false;
        });
    },
    changeAvailability(newAvailability) {
      const { testIdentifier } = this;
      this.$store.commit('answers/CHANGE_ANSWER_STATUS', {
        testIdentifier,
        newAvailability,
      });
      AnswerAPI.updateQuestionStatus(this.testAnswers.docId, newAvailability);

      this.testAnswers = this.$store.getters['answers/allAnswers'].get(testIdentifier);
    },
    questionTypeToTestName(testName) {
      return TestTranslator.questionTypeToTestName(testName);
    },
    async createMetricsForChart() {
      const { allAnswers } = this.testAnswers.data;

      const answerAndQuestionIdMap = new Map();
      allAnswers.forEach((answer) => {
        answerAndQuestionIdMap.set(answer.qid, answer);
      });

      const originalTestIdentifier = this.testIdentifier.replace('_parent', '');
      const snapshot = await QuestionAPI.getAll(originalTestIdentifier);
      this.allQuestionsAndAnswers = [];
      snapshot.forEach((doc) => {
        doc.data().questions.forEach((question) => {
          // If the question is inactive or we do not have an answer for it, skip it
          if (!question.questionActive || !answerAndQuestionIdMap.get(question.id)) return;

          this.allQuestionsAndAnswers.push({
            question,
            answer: answerAndQuestionIdMap.get(question.id),
          });
        });
      });
    },
    deleteResult() {
      const { testIdentifier } = this;
      AnswerAPI.remove(this.testAnswers.docId)
        .then(() => {
          this.$store.commit('answers/DELETE_ANSWER', {
            testIdentifier,
          });
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    IdentiteitAnswerRow,
    IdentiteitCardsResults,
    MoonLoader,
  },
  mounted() {
    this.testAnswers = this.$store.getters['answers/allAnswers'].get(this.testIdentifier);
    this.createMetricsForChart();
  },
  computed: {
    ...mapGetters({
      currentUser: 'answers/user',
      permissions: 'user/permissions',
    }),
  },
};
</script>
