<template>
  <div>
    <div class="d-flex justify-content-center align-items-center">
      <moon-loader v-if="answersLoading" color="#F26000"></moon-loader>
    </div>
    <b-container v-if="!answersLoading">
       <b-row align-h="end">
        <b-col
          cols="2">
          <button
            class="light-gray-button"
            style="width: 45px; height: 45px;"
            @click="forceReload()">
            <b-icon-arrow-counterclockwise/>
          </button>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col
          v-if="allVisibleAnswerSets.length === 0"
          cols="12">
          <p class="my-3">Je hebt nog geen resultaten om te bekijken</p>
        </b-col>

        <b-col
          v-for="data in allVisibleAnswerSets"
          cols="5"
          :class="{
            'blue-card': !data.madeByParent,
            'light-blue-card': data.madeByParent,
          }"
          :key="data.key + data.madeByParent">
          <button
            @click="$emit('selectedTest', data.key)"
            class="no-border-and-background-button"
            style="color: white; width: 100%; height: 100%;">
            <span v-if="data.madeByParent">(Ouder/Verzorger)</span>
            {{ questionTypeToTestName(data.key) }}<b-icon-chevron-right/>
          </button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue';
import { mapGetters } from 'vuex';

import { TestTranslator } from '../../../../Shared/index';

export default {
  name: 'PerformedTestOverview',
  props: ['uid', 'showsHidden', 'loadParentAnswers'],
  methods: {
    loadAllAnswers() {
      this.$store.dispatch(
        'answers/loadAnswersForUser',
        {
          uid: this.uid,
          loadParentAnswers: this.loadParentAnswers,
          forced: false,
        },
      );
    },
    questionTypeToTestName(testName) {
      return TestTranslator.questionTypeToTestName(testName);
    },
    forceReload() {
      this.$store.dispatch(
        'answers/loadAnswersForUser',
        {
          uid: this.uid,
          loadParentAnswers: this.loadParentAnswers,
          forced: true,
        },
      );
    },
  },
  mounted() {
    this.loadAllAnswers();
  },
  computed: {
    ...mapGetters({
      allAnswers: 'answers/allAnswers',
      answersLoading: 'answers/isLoading',
    }),
    allVisibleAnswerSets() {
      if (!this.allAnswers) return [];

      const presentedAnswers = [];
      this.allAnswers.forEach((data, key) => {
        if (data.data.isVisibleForUser || this.showsHidden) {
          // Make sure we are allow to display the data
          if (data.madeByParent) {
            if (this.loadParentAnswers) {
              presentedAnswers.push({ key, madeByParent: data.madeByParent });
            }
          } else {
            presentedAnswers.push({ key, madeByParent: data.madeByParent });
          }
        }
      });

      return presentedAnswers;
    },
  },
  components: {
    MoonLoader,
  },
};
</script>
