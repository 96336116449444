<template>
  <b-form @submit.stop.prevent="onSubmit">
    <b-form-group>
      <b-form-input
        name="password"
        v-model="$v.form.password.$model"
        :state="validateState('password')"
        aria-describedby="password-feedback"
        placeholder="Wachtwoord"
        type="password"
        autocomplete="new-password"
      ></b-form-input>

      <b-form-invalid-feedback id="password-feedback">
        Een wachtwoord moet minimaal 8 karakters,
        één cijfer en één speciaal karakter bevatten.
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        name="passwordValidation"
        v-model="$v.form.passwordValidation.$model"
        :state="validateState('passwordValidation')"
        aria-describedby="passwordValidation-feedback"
        placeholder="Wachtwoord (controle)"
        type="password"
        autocomplete="new-password"
      ></b-form-input>

      <b-form-invalid-feedback id="passwordValidation-feedback">
        De twee wachtwoorden komen niet met elkaar overeen
      </b-form-invalid-feedback>
    </b-form-group>

    <button class="blue-button w-100" type="submit">
      Opslaan
    </button>
  </b-form>
</template>

<script>
import {
  required, sameAs, minLength,
} from 'vuelidate/lib/validators';
import { auth } from '../../../../Shared/lib/firebase';

function passwordContainsSpecialCharacters(value) {
  // eslint-disable-next-line no-useless-escape
  const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
  return strongRegex.test(value);
}

export default {
  name: 'UpdatePassword',
  data: () => {
    return {
      form: {
        password: '',
        passwordValidation: '',
      },
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        passwordContainsSpecialCharacters,
      },
      passwordValidation: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$bvToast.toast('Niet alle velden zijn correct ingevuld', {
          toaster: 'b-toaster-bottom-full',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          appendToast: false,
        });
      } else {
        const user = auth.currentUser;
        user.updatePassword(this.form.password).then(() => {
          this.$bvToast.toast('Het wachtwoord is aangepast', {
            toaster: 'b-toaster-bottom-full',
            autoHideDelay: 5000,
            variant: 'info',
            solid: true,
            appendToast: false,
          });
          this.$bvModal.hide('change-password-modal');
        }).catch((error) => {
          this.$bvToast.toast(error.message, {
            toaster: 'b-toaster-bottom-full',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            appendToast: false,
          });
          this.$bvModal.hide('change-password-modal');
        });
      }
    },
  },
};
</script>
