import { AnswerSubmissionLogAPI } from '../../../../Shared/index';

const getters = {
  log: (state) => state.log,
  last_updated: (state) => state.last_updated,
};

const mutations = {
  SET_CURRENT_LOG(state, logs) {
    state.log = logs;
    state.last_updated = new Date();
  },
  FLUSH(state) {
    state.log = [];
    state.last_updated = null;
  },
};

const actions = {
  reloadLog({ commit }, { days, filterSchoolName }) {
    const today = new Date();
    today.setDate(today.getDate() - (+days));

    let getter;
    if (filterSchoolName) {
      getter = AnswerSubmissionLogAPI.getAllFilteredOnSchool(today, filterSchoolName.toLowerCase());
    } else {
      getter = AnswerSubmissionLogAPI.getAll(today);
    }

    getter.then((snapshot) => {
      const logs = [];
      snapshot.forEach((doc) => {
        logs.push(doc.data());
      });

      logs.sort((a, b) => {
        const dateA = new Date(a.uploadDate.seconds);
        const dateB = new Date(b.uploadDate.seconds);
        return dateB - dateA;
      });

      commit('SET_CURRENT_LOG', logs);
    });
  },
  flushStore({ commit }) {
    commit('FLUSH');
  },
};

export default {
  namespaced: true,
  state: {
    log: [],
    last_updated: null,
  },
  getters,
  mutations,
  actions,
};
