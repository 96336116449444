import Vue from 'vue';
import { BootstrapVue, IconsPlugin, LayoutPlugin } from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import { auth } from '../../Shared/lib/firebase';

import App from './App.vue';
import router from './router';
import store from './store';

import './assets/scss/main.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(LayoutPlugin);
Vue.use(Vuelidate);

Vue.config.productionTip = false;

auth.onAuthStateChanged((user) => {
  store.dispatch('user/fetchUser', user);
  if (user && auth.currentUser) {
    auth.currentUser.getIdTokenResult().then((idToken) => {
      store.dispatch('user/updatePermissions', idToken.claims);
    });
    store.dispatch('user/loadUserData');
  }

  if (user === null) {
    // Crear the stores
    store.dispatch('answers/flushStore');
    store.dispatch('answerSubmissionLog/flushStore');
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
