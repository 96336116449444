import {
  QuestionAPI,
} from '../../../Shared/index';

// eslint-disable-next-line import/prefer-default-export
export async function createMetricsForRiasocChart(answers) {
  const answerAndQuestionIdMap = new Map();
  answers.allAnswers.forEach((answer) => {
    answerAndQuestionIdMap.set(answer.qid, answer);
  });

  const questions = new Map();

  const snapshot = await QuestionAPI.getAll(
    answers.questionType,
  );

  const allQuestionsAndAnswers = [];
  snapshot.forEach((doc) => {
    doc.data().questions.forEach((question) => {
      // If the question is inactive or we do not have an answer for it, skip it
      if (!question.questionActive || !answerAndQuestionIdMap.get(question.id)) return;

      questions.set(question.id, question);
      allQuestionsAndAnswers.push({
        question,
        answer: answerAndQuestionIdMap.get(question.id),
      });
    });
  });

  // Sort on riasocType
  allQuestionsAndAnswers.sort((a, b) => {
    if (a.question.riasocType > b.question.riasocType) {
      return 1;
    // eslint-disable-next-line no-else-return
    } else if (a.question.riasocType < b.question.riasocType) {
      return -1;
    }
    return 0;
  });

  const trueTypes = [];
  answers.allAnswers.forEach((answer) => {
    if (answer.answer) {
      try {
        trueTypes.push(questions.get(answer.qid).riasocType);
      } catch (err) {
        console.log(err);
      }
    }
  });

  let scoreMap = new Map([
    ['R', 0],
    ['I', 0],
    ['A', 0],
    ['S', 0],
    ['O', 0],
    ['C', 0],
  ]);

  trueTypes.forEach((categoryId) => {
    scoreMap.set(categoryId, scoreMap.get(categoryId) + 1);
  });

  scoreMap = new Map([...scoreMap.entries()].sort((a, b) => {
    if (a[1] < b[1]) return 1;
    return -1;
  }));

  return scoreMap;
}

function combineAnswersAndQuestions(type, questionsSnapshot, allAnswers) {
  const answerAndQuestionIdMap = new Map();
  allAnswers[type].forEach((answer) => {
    answerAndQuestionIdMap.set(answer.qid, answer);
  });

  const allQuestionsAndAnswers = [];
  questionsSnapshot.forEach((doc) => {
    doc.data().questions.forEach((question) => {
      // If the question is inactive or we do not have an answer for it, skip it
      if (!question.questionActive || !answerAndQuestionIdMap.get(question.id)) return;
      allQuestionsAndAnswers.push({
        question,
        answer: answerAndQuestionIdMap.get(question.id),
      });
    });
  });

  return allQuestionsAndAnswers;
}

export async function createMetricsForWaardenspelChart(answers) {
  const { allAnswers, questionType } = answers;

  const snapshot = await QuestionAPI.getAll(questionType);

  const notFittingAnswersAndQuestion = combineAnswersAndQuestions(
    'not_fitting',
    snapshot,
    allAnswers,
  );

  const notSelectedAnswersAndQuestion = combineAnswersAndQuestions(
    'not_selected',
    snapshot,
    allAnswers,
  );

  const selectedAnswersAndQuestion = combineAnswersAndQuestions(
    'selected',
    snapshot,
    allAnswers,
  );

  return {
    notFittingAnswersAndQuestion,
    notSelectedAnswersAndQuestion,
    selectedAnswersAndQuestion,
  };
}

export async function createMetricsForIdentiteitChart(answers) {
  const { allAnswers } = answers;
  const answerAndQuestionIdMap = new Map();
  allAnswers.forEach((answer) => {
    answerAndQuestionIdMap.set(answer.qid, answer);
  });

  const snapshot = await QuestionAPI.getAll(answers.questionType);
  const allQuestionsAndAnswers = [];
  snapshot.forEach((doc) => {
    doc.data().questions.forEach((question) => {
      // If the question is inactive or we do not have an answer for it, skip it
      if (!question.questionActive || !answerAndQuestionIdMap.get(question.id)) return;
      allQuestionsAndAnswers.push({
        question,
        answer: answerAndQuestionIdMap.get(question.id),
      });
    });
  });
  return allQuestionsAndAnswers;
}
