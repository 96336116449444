<template>
  <div class="my-5">
    <div>
      <b-container class="py-3">
        <b-row align-v="center" align-h="center">
          <b-col cols="5">
            <b-form-select
              name="selectedTest"
              v-model="selectedTest"
              :options="tests"
              @change="reloadQuestions()"
            ></b-form-select>
          </b-col>
          <b-col cols="5">
            <button
              class="orange-button px-3"
              :disabled="selectedTest === 'null'"
              @click="createNewQuestion()"
            >
              <b-icon-plus icon="type-bold"></b-icon-plus> Voeg een vraag toe
            </button>
          </b-col>
        </b-row>
      </b-container>

      <p v-if="questions.length === 0">Selecteer een test</p>
      <div v-if="selectedTest.includes('riasoc')">
        <RiasocQuestionRow
          v-for="question in questions"
          :question="question"
          :key="question.id"
          :questionsSetId="questionsSetId"
          :class="{ disabled: !question.questionActive }">
        </RiasocQuestionRow>
      </div>
      <div v-if="selectedTest.includes('identiteit')">
        <TwoSidedQuestionRow
          v-for="question in questions"
          :question="question"
          :key="question.id"
          :questionsSetId="questionsSetId"
          :class="{ disabled: !question.questionActive }"
          questionTypeName="identiteit">
        </TwoSidedQuestionRow>
      </div>
      <div v-if="selectedTest.includes('waardenspel')">
        <TwoSidedQuestionRow
          v-for="question in questions"
          :question="question"
          :key="question.id"
          :questionsSetId="questionsSetId"
          :class="{ disabled: !question.questionActive }"
          questionTypeName="identiteit">
        </TwoSidedQuestionRow>
      </div>
    </div>

    <b-modal
      ref="new-question-modal"
      id="new-question-modal"
      hide-footer title="Maak een nieuwe vraag aan"
    >
      <div v-if="selectedTest.includes('riasoc')">
        <EditRiasocQuestion
          :questionSetId="questionsSetId"></EditRiasocQuestion>
      </div>
      <div v-if="selectedTest.includes('identiteit')">
        <EditTwoSidedCardQuestion
          :questionSetId="questionsSetId"
          questionTypeName="identiteit"></EditTwoSidedCardQuestion>
      </div>
      <div v-if="selectedTest.includes('waardenspel')">
        <EditTwoSidedCardQuestion
          :questionSetId="questionsSetId"
          questionTypeName="waardenspel"></EditTwoSidedCardQuestion>
      </div>
    </b-modal>
  </div>
</template>

<script>
import RiasocQuestionRow from '@/components/editQuestions/RiasocQuestionRow.vue';
import EditRiasocQuestion from '@/components/editQuestions/EditRiasocQuestion.vue';
import TwoSidedQuestionRow from '@/components/editQuestions/TwoSidedQuestionRow.vue';
import EditTwoSidedCardQuestion from '@/components/editQuestions/EditTwoSidedCardQuestion.vue';

import { QuestionAPI } from '../../../../Shared/index';

export default {
  name: 'EditQuestions',
  data: () => {
    return {
      selectedTest: 'null',
      tests: [
        {
          value: 'null',
          text: 'Selecteer een test',
        },
        {
          value: 'riasoc_kiesreis',
          text: 'RIASOC Reis',
        },
        {
          value: 'riasoc_general',
          text: 'RIASOC Algemeen',
        },
        {
          value: 'identiteit',
          text: 'Identiteit',
        },
        {
          value: 'waardenspel',
          text: 'Waardenspel',
        },
      ],
      questionsSetId: null,
      questions: [],
    };
  },
  components: {
    RiasocQuestionRow,
    EditRiasocQuestion,
    TwoSidedQuestionRow,
    EditTwoSidedCardQuestion,
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.reloadQuestions();
    });
  },
  methods: {
    createNewQuestion() {
      this.$refs['new-question-modal'].show();
    },
    reloadQuestions() {
      const sortQuestionsAlphabetically = (a, b) => {
        if (a.question < b.question) {
          return -1;
        // eslint-disable-next-line no-else-return
        } else if (a.question > b.question) {
          return 1;
        }
        return 0;
      };

      if (this.selectedTest === 'null') return;
      QuestionAPI.getAll(this.selectedTest)
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            this.questions = doc.data().questions;
            this.questions.sort(sortQuestionsAlphabetically);
            this.questionsSetId = doc.id;
          });
        })
        .catch((error) => {
          console.log(`[Reload questions error] ${error}`);
        });
    },
  },
};
</script>
