<template>
  <b-container
    fluid class="question-row" style="cursor: pointer;"
    @click="$emit('openSubmissionModal', log)">

    <b-row align-v="center" align-h="center">
      <b-col cols="2" class="text-left">
        <strong>{{log.userName}}</strong>
      </b-col>
      <b-col cols="4" class="text-left">
        {{log.userEmail}}
      </b-col>
      <b-col cols="3" class="text-left">
        {{ questionTypeToTestName(log.questionType) }}
      </b-col>
      <b-col cols="3" class="text-left">
        {{ formatDate(log.uploadDate) }}
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment';
import { TestTranslator } from '../../../../Shared/index';

export default {
  name: 'AnswerSubmissionsRow',
  props: ['log'],
  methods: {
    questionTypeToTestName(testName) {
      return TestTranslator.questionTypeToTestName(testName);
    },
    formatDate(date) {
      moment.locale('nl');
      return moment.unix(date.seconds).format('lll');
    },
  },
};
</script>
