<template>
  <div class="content-container">
    <scale-loader v-if="chartLoading" color="#F26000"></scale-loader>

    <div class="yes-no-column-container">
      <div class="column no-column">
        <h3>NEE, dit past niet bij mij</h3>
        <hr/>
        <div class="bubble-container">
          <img
            v-for="answer in falseAnswers"
            :key="answer.question.qid"
            :src="answer.question.backImage"
            :alt="answer.question.backImageAlt"
            class="image-bubble image-bubble-animated"
            :ref="'bubble-' + answer.answer.qid"
            @click="showBubbleDetail(answer)"
          />
        </div>
      </div>

      <div class="column yes-column">
        <h3>JA, dit past bij mij</h3>
        <hr/>
        <div class="bubble-container">
          <img
            v-for="answer in trueAnswers"
            :key="answer.answer.qid"
            :src="answer.question.backImage"
            :alt="answer.question.backImageAlt"
            class="image-bubble image-bubble-animated"
            :ref="'bubble-' + answer.answer.qid"
            @click="showBubbleDetail(answer)"
          />
        </div>
      </div>
    </div>

    <img
      ref="floatingBubble"
      class="image-bubble floating-bubble hidden"
      alt="Identiteit Kaart"
    />

    <div class="bubble-explanation-overlay hidden scene" ref="overlay">
      <div class="identiteit-card" ref="card" @click="rotateCard">
        <img
          class="large-image-bubble identiteit-card__face"
          ref="overlayFrontImage"
          alt="Indentiteit Kaart"
        />
        <img
          class="large-image-bubble identiteit-card__face identiteit-card__back_face"
          ref="overlayBackImage"
          alt="Indentiteit Kaart"
        />
      </div>

      <div class="overlay-button-container">
        <button class="overlay-button" @click="rotateCard">
          Draaien
        </button>
        <button class="overlay-button" @click="closeOverlay">
          Sluiten
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';

export default {
  name: 'IdentiteitCardsResults',
  props: ['results'],
  methods: {
    rotateCard() {
      this.$refs.card.classList.toggle('is-flipped');
      this.isRotated = !this.isRotated;
    },
    setDetailImages(question) {
      const { floatingBubble, overlayFrontImage, overlayBackImage } = this.$refs;

      floatingBubble.src = question.backImage;
      overlayFrontImage.src = question.backImage;
      overlayBackImage.src = question.frontImage;
    },
    showBubbleDetail(answer) {
      const bubbleId = answer.answer.qid; // The question id also works as answer id
      const { question } = answer;
      this.currentOverlayAnswer = answer;

      const { floatingBubble } = this.$refs;
      const originBubble = this.$refs[`bubble-${bubbleId}`][0];
      const { overlay } = this.$refs;
      this.currentClickedBubble = originBubble;
      this.setDetailImages(question);

      const bubbleMargin = 20;

      const rect = originBubble.getBoundingClientRect();
      floatingBubble.style.left = `${(rect.left - bubbleMargin)}px`;
      floatingBubble.style.top = `${(rect.top - bubbleMargin)}px`;

      floatingBubble.classList.remove('hidden');

      let targetWidthHeight = 0.0;
      if (window.innerWidth <= 767) {
        // in the case of a mobile device the card has a width/height of 80vw
        targetWidthHeight = 0.8 * window.innerWidth;
      } else {
        // in the case of a 'large' device, the card has a width/heigh of 70vh
        targetWidthHeight = 0.7 * window.innerHeight;
      }

      const currentWidthHeight = floatingBubble.clientHeight;
      const scaleFactor = targetWidthHeight / currentWidthHeight;
      const overlayButtonContainerHeight = 45;

      const screenCenterX = (window.innerWidth / 2) - (currentWidthHeight / 2) - bubbleMargin;
      const screenCenterY = (window.innerHeight / 2) - (currentWidthHeight / 2)
                  - bubbleMargin - overlayButtonContainerHeight;

      setTimeout(() => {
        floatingBubble.style.left = `${screenCenterX}px`;
        floatingBubble.style.top = `${screenCenterY}px`;
        floatingBubble.style.transform = `scale(${scaleFactor})`;
        setTimeout(() => {
          overlay.classList.remove('hidden');
          setTimeout(() => {
            overlay.style.opacity = 1.0;
            setTimeout(() => {
              floatingBubble.style.opacity = 0.0;
              setTimeout(() => {
                floatingBubble.classList.add('hidden');
              }, 400);
            }, 400);
          }, 10);
        }, 500);
      }, 0);
    },
    closeOverlay() {
      const targetBubble = this.currentClickedBubble;
      const { floatingBubble, overlay } = this.$refs;

      this.currentClickedBubble = null;

      const rect = targetBubble.getBoundingClientRect();
      const bubbleMargin = 20;

      let rotationDelay = 0;
      if (this.isRotated) {
        this.rotateCard();
        rotationDelay = 1000;
      }

      setTimeout(() => {
        floatingBubble.classList.remove('hidden');
        floatingBubble.style.opacity = 1.0;
        overlay.style.opacity = 0.0;

        setTimeout(() => {
          overlay.classList.add('hidden');
          floatingBubble.style.left = `${(rect.left - bubbleMargin)}px`;
          floatingBubble.style.top = `${(rect.top - bubbleMargin)}px`;
          floatingBubble.style.transform = 'scale(1.0)';
          setTimeout(() => {
            floatingBubble.classList.add('hidden');
          }, 500);
        }, 400);
      }, rotationDelay);
    },
    prepareData(results) {
      const trueAnswers = [];
      const falseAnswers = [];
      results.forEach((result) => {
        if (result.answer.answer) {
          trueAnswers.push(result);
        } else {
          falseAnswers.push(result);
        }
      });

      this.trueAnswers = trueAnswers;
      this.falseAnswers = falseAnswers;
      this.chartLoading = false;
    },
  },
  data() {
    return {
      trueAnswers: [],
      falseAnswers: [],

      chartLoading: false,
      currentClickedBubble: null,
      isRotated: false,
      currentOverlayAnswer: false,
    };
  },
  mounted() {
    this.chartLoading = true;

    if (this.results) {
      this.chartLoading = false;
      this.prepareData(this.results);
    }
  },
  components: {
    ScaleLoader,
  },
  watch: {
    results(newValue) {
      this.prepareData(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.content-container {
  @include flex-column(center);
  padding: 0 $standard-margin-size;
  @include sm-max {
    width: calc(100vw - #{$standard-margin-size});
  }
  @include md {
    width: calc(100% - #{$standard-margin-size});
  }
  & button {
    margin: 20px;
  }
  & p {
    margin: 20px;
  }
}

.yes-no-column-container {
  @include flex-row(space-between);
  @include sm-max {
    align-self: center;
    width: 90%;
  }
  @include md {
    width: 100%;
  }
}

.column {
  width: 50%;
  & hr {
    border: 1px solid rgba($gray, 0.5);
    margin: 0 20px;
  }
}

.yes-column {
  color: $green;
}

.no-column {
  border-right: 1px solid rgba($gray, 0.3);
  color: $red;
}

.primary-button-orange {
  flex-basis: 100%;
}

.bubble-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.image-bubble {
  @include sm-max {
    width: 100px;
    height: 100px;
  }
  @include md {
    width: 170px;
    height: 170px;
  }

  border-radius: 50%;
  margin: 20px;
  border: 2px solid $orange;
  cursor: pointer;
  transform: scale(0.0);
  background-color: red;

  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    @include shadow(0, 1px, 15px, 2px, rgba(0, 0, 0, 0.15));
  }
}

@for $i from 1 through 100 {
  .image-bubble-animated:nth-of-type(#{$i}) {
    animation: pop-animation .5s (random() + s) cubic-bezier(0.25, 0, 0.5, 1) forwards;
  }
}

@keyframes pop-animation {
  100% {
    transform: scale(1.0);
    opacity: 1.0;
  }
}

.bubble-explanation-overlay {
  @include flex-column(center);
  transition: opacity 0.4s;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  max-width: none;
  background-color: rgba(white, 0.5);
  opacity: 0.0;
}

.overlay-button-container {
  @include flex-row(center);
  margin: 20px;
  max-width: none;
  & .overlay-button {
    @include basicButton;
    @include shadow(0, 1px, 15px, 2px, rgba(0, 0, 0, 0.15));
    align-self: center;
    border: 1px solid rgba(gray, 0.2);
    background-color: white;
    border-radius: 25px;
    font-size: 13px;
    width: 150px;
    height: 50px;
    margin: 0 5px;
  }
}

.floating-bubble {
  @include shadow(0, 1px, 15px, 2px, rgba(0, 0, 0, 0.15));
  transform: scale(1.0);
  position: fixed;
  border: none;
  transition: transform .5s ease-in-out, left .5s ease-in-out, top .5s ease-in-out, opacity .4s;
}

.hidden {
  display: none;
}

.large-image-bubble {
  @include shadow(0, 1px, 15px, 2px, rgba(0, 0, 0, 0.15));
  @include sm-max {
    width: 80vw;
    height: 80vw;
  }
  @include md {
    width: 70vh;
    height: 70vh;
  }
  border-radius: 50%;
  border: none;
}

.scene {
  width: 100%;
  height: 100%;
  perspective: 600px;
}

.identiteit-card {
  align-self: center;
  @include sm-max {
    width: 80vw;
    height: 80vw;
  }
  @include md {
    width: 70vh;
    height: 70vh;
  }
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
}

.identiteit-card.is-flipped {
  transform: rotateY(180deg);
}

.identiteit-card__face {
  position: absolute;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.identiteit-card__back_face {
  transform: rotateY(180deg);
}

</style>
