import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import user from './modules/user';
import answers from './modules/answers';
import answerSubmissionLog from './modules/answerSubmissionLog';

Vue.use(Vuex);

const debug = true;
const plugins = [];

plugins.push(createPersistedState({ paths: ['user'] }));

export default new Vuex.Store({
  modules: {
    user,
    answers,
    answerSubmissionLog,
  },
  strict: debug,
  plugins,
});
