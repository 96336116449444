<template>
  <div class="user-search-container">
    <h5>Zoeken naar een gebruiker</h5>
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group>
        <b-form-input
          name="firstName"
          v-model="$v.form.firstName.$model"
          :state="validateState('firstName')"
          aria-describedby="firstName-feedback"
          placeholder="Voornaam"
          type="text"
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <b-form-input
          name="lastName"
          v-model="$v.form.lastName.$model"
          :state="validateState('lastName')"
          aria-describedby="lastName-feedback"
          placeholder="Achternaam"
          type="text"
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <b-form-input
          name="schoolName"
          v-model="$v.form.schoolName.$model"
          :state="validateState('schoolName')"
          aria-describedby="schoolName-feedback"
          placeholder="School Naam"
          type="text"
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <b-form-input
          name="className"
          v-model="$v.form.className.$model"
          :state="validateState('className')"
          aria-describedby="className-feedback"
          placeholder="Klas"
          type="text"
        ></b-form-input>
      </b-form-group>

      <button class="blue-button w-100" type="submit">
        <b-icon-search/> Zoeken
      </button>
    </b-form>

    <!-- List of users -->
    <UserRow
      v-for="user in allUsers"
      :user="user"
      :key="`${user.uid}_${user.kieswijsCode}`"
      @selectedUser="$emit('selectedUser', $event)">
    </UserRow>
  </div>
</template>

<script>
import UserRow from '@/components/viewUsers/UserRow.vue';
import { UserDataAPI } from '../../../../Shared/index';

export default {
  name: 'UserSearch',
  data: () => {
    return {
      form: {
        firstName: '',
        lastName: '',
        schoolName: '',
        className: '',
      },
      allUsers: [],
    };
  },
  methods: {
    onSubmit() {
      this.allUsers = [];

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$bvToast.toast('Niet alle velden zijn correct ingevuld', {
          toaster: 'b-toaster-bottom-full',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          appendToast: false,
        });
        return;
      }

      UserDataAPI
        .getUserUsingData({
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          schoolName: this.form.schoolName,
          className: this.form.className,
        })
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            this.allUsers.push(doc.data());
          });
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
  },
  validations: {
    form: {
      firstName: { },
      lastName: { },
      schoolName: { },
      className: { },
    },
  },
  components: {
    UserRow,
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/main.scss';

.user-search-container {
  text-align: left;
  padding: 1em;
  margin: 1em;
  background-color: $light-gray;
}
</style>
