<template>
  <div class="my-5">
    <b-container class="py-3">
      <b-row align-v="center" align-h="center">
        <b-col cols="3">
          <button class="blue-button px-3" @click="$router.go(-1)">
            <b-icon-chevron-left/> Terug
          </button>
        </b-col>
        <b-col cols="6">
          <h3>Werknemers Dashboard</h3>
        </b-col>
        <b-col cols="3"></b-col>
      </b-row>
    </b-container>

    <div v-if="$router.history.current.name === 'EmployeeDashboard'">
      <b-container class="mt-3">
        <b-row>
          <b-col class="blue-card"
            v-if="permissions.isStaff || permissions.isEditor || permissions.isSuperuser">
            <router-link to="/medewerkers/gebruikers-antwoorden">
              <b-icon-binoculars-fill icon="type-bold" class="mr-2">
              </b-icon-binoculars-fill> Bekijk de resultaten van een kandidaat
            </router-link>
          </b-col>
          <b-col class="blue-card" v-if="permissions.isEditor || permissions.isSuperuser">
            <router-link to="/medewerkers/vragen-aanpassen">
              <b-icon-pencil-fill icon="type-bold" class="mr-2">
              </b-icon-pencil-fill> Tests aanpassen
            </router-link>
          </b-col>
        </b-row>
      </b-container>

      <AnswerSubmissionsList
        class="mt-5"
        style="max-height: 500px; overflow-y: auto;"/>
    </div>
    <router-view/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AnswerSubmissionsList from '@/components/employeeComponents/AnswerSubmissionsList.vue';

export default {
  name: 'EmployeeDashboard',
  components: {
    AnswerSubmissionsList,
  },
  computed: {
    ...mapGetters({
      permissions: 'user/permissions',
    }),
  },
};
</script>
