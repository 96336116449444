<template>
  <div class="my-5">
    <h1>404</h1>
    <h5>Deze pagina kon niet worden gevonden</h5>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 10em !important;
  font-weight: bold !important;
}
</style>
