<template>
  <footer class="d-flex justify-content-center align-items-center py-2">
    <p class="m-0">&copy; Kieswijs 2021</p>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/main.scss';

footer {
  background-color: $dark-gray;
  color: white;
  & p {
    font-size: 0.8em;
  }
}
</style>
