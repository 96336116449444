<template>
  <div class="m-5" v-if="testAnswers">
    <div class="d-flex justify-content-between align-items-center visibility-bar w-100">
      <div class="d-flex align-items-center">
        <div
          class="availability-indicator"
          :class="{
            'is-available': testAnswers.data.isVisibleForUser,
            'is-unavailable': !testAnswers.data.isVisibleForUser
            }"></div>
        <span class="m-0 d-none d-md-inline">Beschikbaar voor kandidaat.</span>
      </div>

      <button
        class="orange-button"
        v-if="!testAnswers.data.isVisibleForUser"
        @click="changeAvailability(true)">
        Maak nu beschikbaar
      </button>
      <button
        class="orange-button"
        v-if="testAnswers.data.isVisibleForUser"
        @click="changeAvailability(false)">
        Maak onbeschikbaar
      </button>
    </div>

    <div class="w-100 mt-2 d-flex justify-content-center">
      <button
        v-if="!pdfLoading"
        class="blue-button w-100 mt-2"
        @click="generateReport()">
        Downloaden (PDF)
      </button>
      <moon-loader v-if="pdfLoading" color="#F26000"></moon-loader>
    </div>

  <h5
    class="mt-4"
    v-if="selectedAnswersAndQuestion.length > 0 && notFittingAnswersAndQuestion.length > 0"
  >
    Waardenspel Antwoorden
  </h5>
    <h6>De {{selectedAnswersAndQuestion.length}}
        waarden die door de kandidaat geselecteerd zijn</h6>
    <!-- {{selectedAnswersAndQuestion}} -->
    <waarden-cards-view
      :allCards="selectedAnswersAndQuestion"
    />

    <h6>{{notFittingAnswersAndQuestion.length}} waarden die totaal niet bij de kandidaat passen</h6>
    <waarden-cards-view
      :allCards="notFittingAnswersAndQuestion"
    />
  </div>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue';
import WaardenCardsView from '@/components/waardenDetails/WaardenCardsView.vue';
import { mapGetters } from 'vuex';
import {
  AnswerAPI, PDFGeneration, TestTranslator,
} from '../../../../../../Shared/index';
import {
  createMetricsForWaardenspelChart,
} from '../../../../helpers/answerPreparation';

export default {
  name: 'WaardenAnswers',
  props: ['testIdentifier'],
  data: () => {
    return {
      selectedAnswersAndQuestion: [],
      notFittingAnswersAndQuestion: [],
      notSelectedAnswersAndQuestion: [],
      testAnswers: null,
      pdfLoading: false,
    };
  },
  methods: {
    generateReport() {
      this.pdfLoading = true;
      PDFGeneration.generateWaardenReport(
        this.selectedAnswersAndQuestion,
        this.notFittingAnswersAndQuestion,
        `${this.userInformation.firstName} ${this.userInformation.lastName}`,
      )
        .then(() => {
          this.pdfLoading = false;
        });
    },
    questionTypeToTestName(testName) {
      return TestTranslator.questionTypeToTestName(testName);
    },
    changeAvailability(newAvailability) {
      const { testIdentifier } = this;

      this.$store.commit('answers/CHANGE_ANSWER_STATUS', {
        testIdentifier,
        newAvailability,
      });
      AnswerAPI.updateQuestionStatus(this.testAnswers.docId, newAvailability);
      this.testAnswers = this.$store.getters['answers/allAnswers'].get(this.testIdentifier);
    },
  },
  components: {
    WaardenCardsView,
    MoonLoader,
  },
  async mounted() {
    this.testAnswers = this.$store.getters['answers/allAnswers'].get(this.testIdentifier);

    const data = await createMetricsForWaardenspelChart(this.testAnswers.data);
    this.notFittingAnswersAndQuestion = data.notFittingAnswersAndQuestion;
    this.notSelectedAnswersAndQuestion = data.notSelectedAnswersAndQuestion;
    this.selectedAnswersAndQuestion = data.selectedAnswersAndQuestion;
  },
  computed: {
    ...mapGetters({
      userInformation: 'user/userInformation',
    }),
  },
};
</script>
