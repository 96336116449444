<template>
  <div class="mx-5">
    <h5 class="mb-3">Bekijk snel de ingevulde tests</h5>

    <b-form-select
      name="timeFrame"
      v-model="selectedTimeFrame"
      :options="timeFrameOptions"
      aria-describedby="timeFrame"
      @change="changedTimeFrame($event)"
      class="mb-3"
    ></b-form-select>

    <p>Filters:</p>
    <b-form-input
      name="schoolName"
      v-model="filterSchoolName"
      aria-describedby="schoolName-feedback"
      placeholder="Naam School/Onderwijsinstelling"
      type="text"
      class="mb-3"
    ></b-form-input>

    <div>
      <p v-if="logs.length === 0">Er kon geen data worden gevonden met deze filters</p>
      <AnswerSubmissionsRow
        v-for="log in logs"
        :key="`${log.answerDocumentId}_${log.uploadDate.nanoseconds}`"
        :log="log"
        @openSubmissionModal="openSubmissionModal"/>
    </div>

    <button
      class="blue-button w-100 mb-3"
      @click="reloadPastTests()">
      Druk hier om {{ loadedOnce ? 'herladen' : 'op te halen' }}
    </button>

    <p v-if="lastUpdated"><em>Laatste update om: {{ lastUpdated }}</em></p>

    <b-modal centered ref="log-modal" hide-footer title="Antwoord Informatie">
      <div v-if="currentModalData && !pdfLoading">
        <h4>{{currentModalData.userName}}</h4>
        <p><em>{{currentModalData.userEmail}}</em></p>
        <p>{{questionTypeToTestName(currentModalData.questionType)}}
          - {{formatDate(currentModalData.uploadDate)}}</p>

        <div class="d-flex justify-content-between align-items-center visibility-bar">
          <div class="d-flex align-items-center">
            <div
              class="availability-indicator"
              :class="{
                'is-available': currentModalAnswerData.isVisibleForUser,
                'is-unavailable': !currentModalAnswerData.isVisibleForUser
                }"></div>
            <span class="m-0 d-none d-md-inline">Beschikbaar voor kandidaat.</span>
          </div>

          <button
            class="orange-button"
            v-if="!currentModalAnswerData.isVisibleForUser"
            @click="changeAvailability(true)">
            Maak nu beschikbaar
          </button>
          <button
            class="orange-button"
            v-if="currentModalAnswerData.isVisibleForUser"
            @click="changeAvailability(false)">
            Maak onbeschikbaar
          </button>
        </div>

        <button
          @click="generatePDFFromSubmissionData(currentModalData, currentModalAnswerData)"
          class="gray-button w-100 mt-3">
          Download de resultaten als PDF
        </button>
        <button
          @click="openUsersInformation(currentModalAnswerData)"
          class="light-gray-button w-100 mt-3">
          Open de resultaten pagina
        </button>
      </div>

      <div
        v-if="pdfLoading"
        style="height: 200px;"
        class="d-flex flex-column justify-content-center align-items-center"
      >
        <moon-loader color="#F26000" class="mb-3"></moon-loader>
        <p>PDF aan het genereren...</p>
      </div>

      <button class="blue-button mt-3 w-100" @click="hideModal">Sluiten</button>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import MoonLoader from 'vue-spinner/src/MoonLoader.vue';

import {
  AnswerAPI,
  TestTranslator,
  PDFGeneration,
  UserDataAPI,
} from '../../../../Shared/index';

import {
  createMetricsForRiasocChart,
  createMetricsForWaardenspelChart,
  // eslint-disable-next-line import/named
  createMetricsForIdentiteitChart,
} from '../../helpers/answerPreparation';
import AnswerSubmissionsRow from './AnswerSubmissionRow.vue';

export default {
  name: 'AnswerSubmissionsList',
  data: () => {
    return {
      loadedOnce: false,
      currentModalData: null,
      currentModalAnswerData: null,
      currentModalAnswerDocId: null,
      selectedTimeFrame: '14',
      filterSchoolName: null,
      timeFrameOptions: [
        {
          value: '7',
          text: '7 Dagen',
        },
        {
          value: '14',
          text: '14 Dagen',
        },
        {
          value: '30',
          text: '30 Dagen',
        },
      ],
      pdfLoading: false,
    };
  },
  methods: {
    changedTimeFrame() {
      this.reloadPastTests();
    },
    reloadPastTests() {
      this.loadedOnce = true;
      this.$store.dispatch('answerSubmissionLog/reloadLog', {
        days: this.selectedTimeFrame,
        filterSchoolName: this.filterSchoolName,
      });
    },
    openSubmissionModal(log) {
      AnswerAPI
        .getAnswersWithDocumentId(log.answerDocumentId)
        .then((document) => {
          this.currentModalAnswerDocId = document.id;
          this.currentModalAnswerData = document.data();
          this.currentModalData = log;
          this.$refs['log-modal'].show();
        })
        .catch(() => {
          this.$bvToast.toast('Er is iets fout gegaan met het downloaden van het antwoord', {
            toaster: 'b-toaster-bottom-full',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            appendToast: false,
          });
        });
    },
    hideModal() {
      this.currentModalAnswerData = null;
      this.currentModalData = null;
      this.currentModalAnswerDocId = null;
      this.$refs['log-modal'].hide();
    },
    questionTypeToTestName(testName) {
      return TestTranslator.questionTypeToTestName(testName);
    },
    formatDate(date) {
      moment.locale('nl');
      return moment.unix(date.seconds).format('lll');
    },
    changeAvailability(newAvailability) {
      this.currentModalAnswerData.isVisibleForUser = newAvailability;
      AnswerAPI.updateQuestionStatus(this.currentModalAnswerDocId, newAvailability);
    },
    async generatePDFFromSubmissionData(submissionData, answerData) {
      const { userName, questionType } = submissionData;

      if (questionType === 'riasoc_general') {
        const scoreMap = await createMetricsForRiasocChart(answerData);
        this.pdfLoading = true;
        PDFGeneration.generateRIASOCReport(scoreMap, userName)
          .then(() => {
            this.pdfLoading = false;
          });
      } else if (questionType === 'waardenspel') {
        const results = await createMetricsForWaardenspelChart(answerData);

        this.pdfLoading = true;
        PDFGeneration.generateWaardenReport(
          results.selectedAnswersAndQuestion,
          results.notFittingAnswersAndQuestion,
          userName,
        )
          .then(() => {
            this.pdfLoading = false;
          });
      } else if (questionType === 'identiteit') {
        const results = await createMetricsForIdentiteitChart(answerData);
        this.pdfLoading = true;
        PDFGeneration.generateIdenteit(
          results,
          userName,
        )
          .then(() => {
            this.pdfLoading = false;
          });
      }
    },
    openUsersInformation(answerData) {
      console.log(answerData);

      UserDataAPI.get(answerData.user)
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            this.$store.commit('answers/SET_CURRENT_USER', doc.data());
            this.$router.push({
              name: 'UserInformation',
            });
          });
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
  },
  computed: {
    ...mapGetters({
      logs: 'answerSubmissionLog/log',
      lastUpdated: 'answerSubmissionLog/last_updated',
    }),
  },
  components: {
    AnswerSubmissionsRow,
    MoonLoader,
  },
};
</script>

<style lang="scss">
// @import '../assets/scss/main.scss';

</style>
