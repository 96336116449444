<template>
  <div class="m-5" v-if="testAnswers">
    <div class="w-100 mt-2 d-flex justify-content-center">
      <button
        v-if="!pdfLoading"
        class="blue-button w-100 mt-2"
        @click="generateReport()">
        Downloaden (PDF)
      </button>
      <moon-loader v-if="pdfLoading" color="#F26000"></moon-loader>
    </div>

  <h5 class="mt-4">Antwoorden</h5>
    <IdentiteitCardsResults
      :results="allQuestionsAndAnswers"
      class="w-100"/>
  </div>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue';
import IdentiteitCardsResults from '@/components/identiteitDetails/IdentiteitCardsResults.vue';
import { mapGetters } from 'vuex';
import {
  createMetricsForIdentiteitChart,
} from '../../helpers/answerPreparation';
import { PDFGeneration, TestTranslator } from '../../../../Shared/index';

export default {
  name: 'PersonalIdentiteitAnswers',
  props: ['testIdentifier'],
  data: () => {
    return {
      allQuestionsAndAnswers: null,
      testAnswers: null,
      pdfLoading: false,
    };
  },
  methods: {
    generateReport() {
      this.pdfLoading = true;
      PDFGeneration.generateIdenteit(
        this.allQuestionsAndAnswers,
        `${this.userInformation.firstName} ${this.userInformation.lastName}`,
      )
        .then(() => {
          this.pdfLoading = false;
        });
    },
    questionTypeToTestName(testName) {
      return TestTranslator.questionTypeToTestName(testName);
    },
  },
  components: {
    IdentiteitCardsResults,
    MoonLoader,
  },
  async mounted() {
    this.testAnswers = this.$store.getters['answers/allAnswers'].get(this.testIdentifier);
    this.allQuestionsAndAnswers = await createMetricsForIdentiteitChart(this.testAnswers.data);
  },
  computed: {
    ...mapGetters({
      userInformation: 'user/userInformation',
    }),
  },
};
</script>
