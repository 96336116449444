import { UserDataAPI } from '../../../../Shared/index';

const getters = {
  loggedIn: (state) => state.loggedIn,
  id: (state) => state.id,
  data: (state) => state.data, // email is here
  signInProvider: (state) => state.signInProvider,
  permissions: (state) => state.permissions,
  userInformation: (state) => state.userInformation,
  userInformationDocumentId: (state) => state.userInformationDocumentId,
};

const mutations = {
  SET_LOGGED_IN(state, value) {
    state.loggedIn = value;
  },
  SET_USER(state, data) {
    state.data = data;
    state.id = data.id;
    state.signInProvider = data.providerData[0].providerId;
  },
  FLUSH_USER(state) {
    state.id = null;
    state.data = null;
    state.signInProvider = null;
    state.userInformation = null;

    state.permissions = {
      isStaff: false,
      isEditor: false,
      isSuperuser: false,
    };
  },
  SET_PERMISSION_STATE(state, claims) {
    state.permissions = {
      isStaff: !!claims.is_staff,
      isEditor: !!claims.is_editor,
      isSuperuser: !!claims.is_superuser,
    };
  },
  SET_USER_DATA(state, data) {
    state.userInformationDocumentId = data.documentId;
    state.userInformation = data.userData;
  },
  UPDATE_EMAIL(state, newEmail) {
    state.data.email = newEmail;
  },
};

const actions = {
  fetchUser({ commit }, user) {
    commit('SET_LOGGED_IN', user !== null);

    if (user) {
      commit('SET_USER', {
        id: user.uid,
        displayName: user.displayName,
        email: user.email,
        providerData: user.providerData,
      });
    } else {
      commit('FLUSH_USER');
    }
  },
  updatePermissions({ commit }, claims) {
    commit('SET_PERMISSION_STATE', claims);
  },
  loadUserData({ commit, state }) {
    UserDataAPI.get(state.id)
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          commit('SET_USER_DATA', {
            documentId: doc.id,
            userData: doc.data(),
          });
        });
      }).catch((error) => {
        console.log(`[UserDataAPI error] ${error}`);
      });
  },
};

export default {
  namespaced: true,
  state: {
    loggedIn: false,
    id: null,
    data: null,
    signInProvider: null,
    userInformationDocumentId: null,
    userInformation: null,
    permissions: {
      isStaff: false,
      isEditor: false,
      isSuperuser: false,
    },
  },
  getters,
  mutations,
  actions,
};
