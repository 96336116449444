<template>
  <div class="d-flex flex-column justify-content-between image-upload-box">
    <div class="d-flex justify-content-center align-items-center">
      <b-form-file
        name="pic"
        accept="image/*"
        ref="frontImageInput"
        placeholder="Kies een bestand of sleep deze hier naartoe..."
        drop-placeholder="Sleep bestand hier naartoe..."
        v-model="selectedFile"
      ></b-form-file>

      <p class="input-warning-label m-0">Let op! Foto's mogen maximaal 2.5 MB zijn.</p>
    </div>

    <div
      class="uploader d-flex justify-content-center align-items-center mt-3"
      v-if="selectedImage">
      <img ref="preview" src="#" alt="Preview"/>
      <button
        class="orange-button w-50"
        type='button'
        v-if="!uploadInProgress && !uploadDone"
        @click="uploadImage"
      >
        Uploaden
      </button>
      <div class="loader-box" :class="{ 'hidden': !uploadInProgress }">
        <moon-loader v-if="uploadInProgress" color="#F26000"></moon-loader>
      </div>
      <div class="loader-box" :class="{ 'hidden': !uploadDone }">

        <b-icon-check2 v-if="uploadDone" variant="success"></b-icon-check2>
      </div>
    </div>
  </div>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue';
import Constants from '@/constants';
import { storage, firebaseStorage } from '../../../../Shared/lib/firebase';

export default {
  name: 'ImageUpload',
  props: ['uploadPath'],
  data() {
    return {
      selectedFile: null,
      selectedImage: null,

      uploadInProgress: false,
      uploadDone: false,
      storageRef: storage.ref(),
    };
  },
  methods: {
    uploadImage() {
      this.uploadInProgress = true;

      if (this.selectedImage) {
        if (this.selectedImage.size >= Constants.MAX_FILE_SIZE) {
          this.$bvToast.toast('Afbeelingen mogen maximaal 2.5MB zijn', {
            toaster: 'b-toaster-bottom-full',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            appendToast: false,
          });
          this.uploadInProgress = false;
        } else {
          const uploadTask = this.storageRef
            .child(`images/${this.uploadPath}/${this.selectedImage.name}`)
            .put(this.selectedImage);

          uploadTask.on(firebaseStorage.TaskEvent.STATE_CHANGED, null,
            () => {
              this.$bvToast.toast('Er is iets fout gegaan tijdens het uploaden', {
                toaster: 'b-toaster-bottom-full',
                autoHideDelay: 5000,
                variant: 'danger',
                solid: true,
                appendToast: false,
              });
            }, () => {
              uploadTask.snapshot.ref.getDownloadURL().then((url) => {
                this.uploadInProgress = false;
                this.uploadDone = true;
                this.$emit('imageUploaded', url);
              });
            });
        }
      }
    },
    previewImage(imgSrc) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.preview.src = e.target.result;
      };
      reader.readAsDataURL(imgSrc);
    },
  },
  components: {
    // ScaleLoader,
    MoonLoader,
  },
  watch: {
    selectedFile(file) {
      if (!file) {
        this.selectedImage = null;
        return;
      }

      // eslint-disable-next-line prefer-destructuring
      this.selectedImage = file;
      this.previewImage(this.selectedImage);
    },
  },
};
</script>

<style lang="scss" scoped>
.image-upload-box {
  border: 1px dashed gray;
  border-radius: 10px;
  margin: 10px 0;
  padding: 20px 10px;
}

.input-warning-label {
  font-weight: bold;
  font-size: .6em;
  color: red;
}

.uploader {
  align-items: center;
  & img {
    border: 1px solid rgba(gray, 0.3);
    width: 90px;
    height: 90px;
    padding: 5px;
    margin-right: 20px;
  }
  & .secondary-button-orange {
    font-size: .8em;
    width: calc(100% - 110px);
  }
  & .loader-box {
    text-align: center;
    width: calc(100% - 110px);
    & .icon {
      color: green;
    }
  }
  & .hidden {
    display: none;
  }
}
</style>
