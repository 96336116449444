<template>
  <div class="app d-flex flex-column justify-content-between">
    <div>
      <NavigationBar/>
      <GlobalHeader/>
      <router-view v-if="!isLoading"/>
      <div
        v-if="isLoading"
        class="mt-5 d-flex flex-column justify-content-center align-items-center">
        <moon-loader v-if="isLoading" color="#F26000"></moon-loader>
        <p class="orange-small-label mt-2">Aan het laden....</p>
      </div>
    </div>

    <MyFooter/>
  </div>
</template>

<script>
// @ is an alias to /src
import MoonLoader from 'vue-spinner/src/MoonLoader.vue';
import NavigationBar from '@/components/NavigationBar.vue';
import GlobalHeader from '@/components/GlobalHeader.vue';
import MyFooter from '@/components/general/Footer.vue';
import ifvisible from 'ifvisible.js';
import { Authentication } from '../../Shared/index';

export default {
  name: 'Home',
  data: () => {
    return {
      isLoading: false,
    };
  },
  components: {
    NavigationBar,
    GlobalHeader,
    MyFooter,
    MoonLoader,
  },
  methods: {
    enableWakeup() {
      setTimeout(() => {
        this.checkAuthentication();
      }, 4000);

      // We remove any old event triggers
      ifvisible.off('wakeup');

      // Then we add a trigger to keep track of sign in/out events in other windows
      ifvisible.on('wakeup', () => {
        this.checkAuthentication();
      });
    },
    signOut() {
      Authentication.signOut()
        .then(() => {
          this.isLoading = false;
          if (this.$router.currentRoute.name !== 'Home') {
            this.$router.push({ name: 'Home' });
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    checkAuthentication() {
      // Check if the CSST token exists
      if (Authentication.getCsstCookie() === null && Authentication.userIsSignedIn()) {
        console.log(1);
        this.isLoading = true;
        // If not, and if we are signed in we want to log out
        this.signOut();
      } else if (Authentication.getCsstCookie() === null && !Authentication.userIsSignedIn()) {
        console.log(2);
        this.isLoading = false;
        this.$store.dispatch('user/fetchUser', null);
      } else if (Authentication.getCsstCookie() !== null && !Authentication.userIsSignedIn()) {
        console.log(3);
        this.isLoading = true;
        // If it does, and we are not signed in, we want to sign in
        Authentication.signInIfCsstTokenPresent()
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {
            console.log('sign out');
            this.signOut();
          });
      }
    },
  },
  mounted() {
    // First we check if we should already sign in the user based on the CSST token
    Authentication.signInIfCsstTokenPresent()
      .then(() => {
        this.isLoading = false;
        this.enableWakeup();
      })
      .catch(() => {
        this.isLoading = false;
        this.enableWakeup();
      });
  },
};
</script>

<style lang="scss">
.app {
  min-height: 100vh;
  font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
