<template>
  <div class="my-5">
    <div v-if="$router.history.current.name === 'StaffAnswerOverview'">
      <UserSearch
        @selectedUser="selectedUser($event)"/>
    </div>

    <router-view/>
  </div>
</template>

<script>
import UserSearch from '@/components/viewUsers/UserSearch.vue';

export default {
  name: 'StaffAnswerOverview',
  components: {
    UserSearch,
  },
  methods: {
    selectedUser(user) {
      this.$store.commit('answers/SET_CURRENT_USER', user);
      this.$router.push({
        name: 'UserInformation',
      });
    },
  },
};
</script>
