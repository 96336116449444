import { AnswerAPI, UserDataAPI } from '../../../../Shared/index';

const getters = {
  user: (state) => state.user,
  allAnswers: (state) => state.allAnswers,
  isLoading: (state) => state.isLoading,
};

const mutations = {
  SET_CURRENT_USER(state, user) {
    if (state.user) {
      state.answersShouldBeReloaded = state.user.uid !== user.uid;
    } else {
      state.answersShouldBeReloaded = true;
    }

    state.user = user;
  },
  SET_USER_ANSWERS(state, userAnswers) {
    state.allAnswers = userAnswers;
  },
  CHANGE_ANSWER_STATUS(state, info) {
    state.allAnswers.get(info.testIdentifier).data.isVisibleForUser = info.newAvailability;
  },
  DELETE_ANSWER(state, info) {
    state.allAnswers.delete(info.testIdentifier);
  },
  ENABLE_LOADING(state) {
    state.isLoading = true;
  },
  DISABLE_LOADING(state) {
    state.isLoading = false;
  },
  DISABLE_RELOAD(state) {
    state.answersShouldBeReloaded = false;
  },
  ADD_SINGLE_ANSWER(state, data) {
    state.allAnswers.set(data.data.questionType, { docId: data.id, data: data.data });
  },
  REMOVE_ANSWER_OF_TEST(state, testName) {
    state.allAnswers.delete(testName);
  },
  FLUSH(state) {
    state.user = null;
    state.allAnswers = null;
    state.isLoading = false;
    state.answersShouldBeReloaded = true;
  },
};

const shouldBeLoaded = (forced, state, uid) => {
  if (!forced) {
    if (state.user) {
      if (!state.answersShouldBeReloaded && state.user.uid === uid) return false;
    } else {
      // eslint-disable-next-line no-lonely-if
      if (!state.answersShouldBeReloaded) return false;
    }
  }
  return true;
};

const actions = {
  async loadAnswersForUser({ state, commit }, data) {
    const { uid, loadParentAnswers, forced } = data;

    if (!shouldBeLoaded(forced, state, uid)) return;

    const answerDocuments = new Map();
    const snapshot = await AnswerAPI.getAllAnswersForUser(uid);

    snapshot.forEach((doc) => {
      answerDocuments.set(
        doc.data().questionType,
        { docId: doc.id, data: doc.data(), madeByParent: false },
      );
    });

    if (loadParentAnswers && state.user.kieswijsCode) {
      const parentSnapshot = await UserDataAPI
        .getParentsForUserWithKieswijsID(state.user.kieswijsCode);

      let nrOfParents = 0;
      parentSnapshot.forEach((doc) => {
        nrOfParents += 1;
        AnswerAPI.getAllAnswersForUser(doc.data().uid)
          .then((parentAnswerSnapshot) => {
            parentAnswerSnapshot.forEach((parentAnswerDoc) => {
              answerDocuments.set(
                `${parentAnswerDoc.data().questionType}_parent`,
                {
                  docId: parentAnswerDoc.id,
                  data: parentAnswerDoc.data(),
                  madeByParent: true,
                },
              );
            });

            commit('SET_USER_ANSWERS', answerDocuments);
            commit('DISABLE_LOADING');
            commit('DISABLE_RELOAD');
          });
      });

      if (nrOfParents === 0) {
        commit('SET_USER_ANSWERS', answerDocuments);
        commit('DISABLE_LOADING');
        commit('DISABLE_RELOAD');
      }
    } else {
      commit('SET_USER_ANSWERS', answerDocuments);
      commit('DISABLE_LOADING');
      commit('DISABLE_RELOAD');
    }
  },
  flushStore({ commit }) {
    commit('FLUSH');
  },
};

export default {
  namespaced: true,
  state: {
    user: null,
    allAnswers: null,
    isLoading: false,
    answersShouldBeReloaded: true,
  },
  getters,
  mutations,
  actions,
};
