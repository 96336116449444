<template>
  <b-container class="question-row">
    <b-row align-v="center">
      <b-col cols="4" class="text-left">
        <strong>{{ user.firstName }} {{ user.lastName }}</strong>
      </b-col>
      <b-col cols="5" class="text-center">
        <strong>{{ user.currentEducation }} {{ user.educationYear }}
          <span v-if="user.schoolName || user.className">
            - {{ user.schoolName }} {{ user.className }}
          </span>
        </strong>
      </b-col>
      <b-col cols="3" class="text-right">
        <button class="orange-button w-100" @click="$emit('selectedUser', user)">
          Bekijken <b-icon-arrow-right/>
        </button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'UserRow',
  props: ['user'],
};
</script>
