<template>
  <div class="m-5" v-if="correctTestAnswers">
    <div class="w-100 mt-2 d-flex justify-content-center">
      <button
        v-if="!pdfLoading"
        class="blue-button w-100 mt-2"
        @click="generateReport()">
        Downloaden (PDF)
      </button>
      <moon-loader v-if="pdfLoading" color="#F26000"></moon-loader>
    </div>

    <h5 class="mt-4">Snel Overzicht</h5>
    <div class="d-flex flex-column align-items-center">
      <Chart
        v-if="riasocCounts !== null"
        :riasocCounts="riasocCounts"
        :testName="testIdentifier"
        class="mb-5"/>
    </div>
  </div>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue';

import Chart from '@/components/riasocDetails/chart/Chart.vue';
import { mapGetters } from 'vuex';
import { QuestionAPI, PDFGeneration, TestTranslator } from '../../../../Shared/index';

export default {
  name: 'PersonalRiasocAnswers',
  props: ['testIdentifier'],
  data: () => {
    return {
      allQuestionsAndAnswers: null,
      riasocCounts: null,
      correctTestAnswers: null,
      pdfLoading: false,
    };
  },
  methods: {
    generateReport() {
      this.pdfLoading = true;
      PDFGeneration.generateRIASOCReport(
        this.riasocCounts,
        `${this.userInformation.firstName} ${this.userInformation.lastName}`,
      )
        .then(() => {
          this.pdfLoading = false;
        });
    },
    questionTypeToTestName(testName) {
      return TestTranslator.questionTypeToTestName(testName);
    },
    async createMetricsForChart() {
      const answersForTest = this.correctTestAnswers.data;
      const answerAndQuestionIdMap = new Map();
      answersForTest.allAnswers.forEach((answer) => {
        answerAndQuestionIdMap.set(answer.qid, answer);
      });

      const questions = new Map();
      const snapshot = await QuestionAPI.getAll(this.testIdentifier);
      this.allQuestionsAndAnswers = [];
      snapshot.forEach((doc) => {
        doc.data().questions.forEach((question) => {
          // If the question is inactive or we do not have an answer for it, skip it
          if (!question.questionActive || !answerAndQuestionIdMap.get(question.id)) return;

          questions.set(question.id, question);
          this.allQuestionsAndAnswers.push({
            question,
            answer: answerAndQuestionIdMap.get(question.id),
          });
        });
      });

      // Sort on riasocType
      this.allQuestionsAndAnswers.sort((a, b) => {
        if (a.question.riasocType > b.question.riasocType) {
          return 1;
        // eslint-disable-next-line no-else-return
        } else if (a.question.riasocType < b.question.riasocType) {
          return -1;
        }
        return 0;
      });

      const trueTypes = [];
      answersForTest.allAnswers.forEach((answer) => {
        if (answer.answer) {
          try {
            trueTypes.push(questions.get(answer.qid).riasocType);
          } catch (err) {
            console.log(err);
          }
        }
      });

      let scoreMap = new Map([
        ['R', 0],
        ['I', 0],
        ['A', 0],
        ['S', 0],
        ['O', 0],
        ['C', 0],
      ]);

      trueTypes.forEach((categoryId) => {
        scoreMap.set(categoryId, scoreMap.get(categoryId) + 1);
      });

      scoreMap = new Map([...scoreMap.entries()].sort((a, b) => {
        if (a[1] < b[1]) return 1;
        return -1;
      }));

      this.riasocCounts = scoreMap;
    },
  },
  components: {
    Chart,
    MoonLoader,
  },
  mounted() {
    this.correctTestAnswers = this.$store.getters['answers/allAnswers'].get(this.testIdentifier);
    this.createMetricsForChart();
  },
  computed: {
    ...mapGetters({
      userInformation: 'user/userInformation',
    }),
  },
};
</script>
