<template>
  <div class="my-5">
    <b-container>
      <b-row align-h="center">
        <b-col cols="6">
          <p class="text-left font-weight-bold">Jouw Kieswijs Code:</p>
        </b-col>
        <b-col cols="6">
          <p class="text-right">{{userInformation.kieswijsCode}}</p>
        </b-col>
        <b-col cols="6">
          <p class="text-left font-weight-bold">Naam:</p>
        </b-col>
        <b-col cols="6">
          <p class="text-right">{{`${userInformation.firstName} ${userInformation.lastName}`}}</p>
        </b-col>
        <b-col cols="6">
          <p class="text-left font-weight-bold">Email:</p>
        </b-col>
        <b-col cols="6">
          <p class="text-right">{{data.email}}</p>
        </b-col>
      </b-row>

      <b-row align-h="center" v-if="userInformation.accountType === 'student'">
        <b-col cols="6">
          <p class="text-left font-weight-bold">Huidige educatie:</p>
        </b-col>
        <b-col cols="6">
          <p class="text-right">{{userInformation.currentEducation}}</p>
        </b-col>
        <b-col cols="6">
          <p class="text-left font-weight-bold">Naam School/Onderwijsinstelling:</p>
        </b-col>
        <b-col cols="6">
          <p class="text-right">{{userInformation.schoolName}}</p>
        </b-col>
        <b-col
          cols="6"
          v-if="['vmbo', 'havo', 'vwo'].indexOf(userInformation.currentEducation) > -1">
          <p class="text-left font-weight-bold">Klas:</p>
        </b-col>
        <b-col
          cols="6"
          v-if="['vmbo', 'havo', 'vwo'].indexOf(userInformation.currentEducation) > -1">
          <p class="text-right">{{userInformation.className}}</p>
        </b-col>
        <b-col cols="6">
          <p class="text-left font-weight-bold">Leerjaar:</p>
        </b-col>
        <b-col cols="6">
          <p class="text-right">{{userInformation.educationYear}}</p>
        </b-col>
      </b-row>

      <b-row align-h="center" v-if="userInformation.accountType === 'parent'">
        <b-col cols="6">
          <p class="text-left font-weight-bold">Jouw gekoppelde kinderen:</p>
        </b-col>
        <b-col cols="6">
          <p class="text-right">{{userInformation.childKieswijsCodes.join(', ')}}</p>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col>
          <button class="orange-button w-100" v-b-modal.change-user-info>
            <b-icon-pencil-fill/>
            Aanpassen
          </button>
        </b-col>
        <b-col v-if="data.providerData[0].providerId === 'password'">
          <button
            class="orange-button w-100"
            v-b-modal.change-password-modal>
            <b-icon-shield-lock-fill/>
            Wachtwoord Wijzigen
          </button>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      ref="change-password-modal"
      id="change-password-modal"
      hide-footer
      title="Wachtwoord aanpassen"
    >
      <UpdatePassword/>
    </b-modal>

    <b-modal
      ref="change-user-info"
      id="change-user-info"
      hide-footer
      title="Persoonlijke informatie aanpassen"
    >
      <UpdateUserInformation/>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UpdatePassword from './UpdatePassword.vue';
import UpdateUserInformation from './UpdateUserInformation.vue';

export default {
  name: 'UserPersonalInformation',
  computed: {
    ...mapGetters({
      data: 'user/data',
      userInformation: 'user/userInformation',
    }),
  },
  components: {
    UpdatePassword,
    UpdateUserInformation,
  },
};
</script>
