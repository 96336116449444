import Vue from 'vue';

import VueRouter from 'vue-router';
import ErrorPage from '@/views/ErrorPage.vue';
import Home from '../views/Home.vue';

import EmployeeDashboard from '../views/medewerkers/EmployeeDashboard.vue';
import EditQuestions from '../views/medewerkers/EditQuestions.vue';
import StaffAnswerOverview from '../views/medewerkers/StaffAnswerOverview.vue';
import UserInformation from '../views/medewerkers/staffAnswerOverview/UserInformation.vue';
import IdentiteitAnswers from '../views/medewerkers/staffAnswerOverview/answerViews/IdentiteitAnswers.vue';
import RiasocAnswers from '../views/medewerkers/staffAnswerOverview/answerViews/RiasocAnswers.vue';
import WaardenAnswers from '../views/medewerkers/staffAnswerOverview/answerViews/WaardenAnswers.vue';
import TestAnswers from '../views/medewerkers/staffAnswerOverview/answerViews/TestAnswers.vue';
import UploadTestPDF from '../views/medewerkers/staffAnswerOverview/UploadTestPDF.vue';

import PersonalTestAnswers from '../views/users/PersonalTestAnswers.vue';
import PersonalIdentiteitAnswers from '../views/users/PersonalIdentiteitAnswers.vue';
import PersonalRiasocAnswers from '../views/users/PersonalRiasocAnswers.vue';
import PersonalWaardenAnswers from '../views/users/PersonalWaardenAnswers.vue';

import { auth } from '../../../Shared/lib/firebase';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: 'identiteit-antwoorden',
        component: PersonalIdentiteitAnswers,
        name: 'PersonalIdentiteitAnswers',
        props: true,
      },
      {
        path: 'riasoc-antwoorden',
        component: PersonalRiasocAnswers,
        name: 'PersonalRiasocAnswers',
        props: true,
      },
      {
        path: 'waarden-antwoorden',
        component: PersonalWaardenAnswers,
        name: 'PersonalWaardenAnswers',
        props: true,
      },
      {
        path: 'test-antwoorden',
        component: PersonalTestAnswers,
        name: 'PersonalTestAnswers',
        props: true,
      },
    ],
  },
  {
    path: '/medewerkers',
    name: 'EmployeeDashboard',
    component: EmployeeDashboard,
    children: [
      {
        path: 'vragen-aanpassen',
        name: 'EditQuestions',
        component: EditQuestions,
      },
      {
        path: 'gebruikers-antwoorden',
        name: 'StaffAnswerOverview',
        component: StaffAnswerOverview,
        children: [
          {
            path: 'details',
            component: UserInformation,
            name: 'UserInformation',
            children: [
              {
                path: 'resultaten-pdf-uploaden',
                component: UploadTestPDF,
                name: 'UploadTestPDF',
              },
              {
                path: 'identiteit-antwoorden',
                component: IdentiteitAnswers,
                name: 'IdentiteitAnswers',
                props: true,
              },
              {
                path: 'riasoc-antwoorden',
                component: RiasocAnswers,
                name: 'RiasocAnswers',
                props: true,
              },
              {
                path: 'waarden-antwoorden',
                component: WaardenAnswers,
                name: 'WaardenAnswers',
                props: true,
              },
              {
                path: 'test-antwoorden',
                component: TestAnswers,
                name: 'TestAnswers',
                props: true,
              },
            ],
          },
        ],
      },
    ],
    async beforeEnter(to, from, next) {
      if (!auth.currentUser) {
        next({ name: 'Home' });
        return;
      }

      const idToken = await auth.currentUser.getIdTokenResult();
      if (idToken.claims.is_staff || idToken.claims.is_editor || idToken.claims.is_superuser) {
        next();
      } else {
        next({ name: 'Home' });
      }
    },
  },
  {
    path: '*',
    component: ErrorPage,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
