<template>
  <div class="chart-legend-container">
    <div class="chart-container">
      <chart-row
        v-for="(data, index) in chartData"
        :index="index"
        :value="data.value"
        :highScore="maxScore"
        :title="data.title"
        :key="data.title"
        :description="groupDescriptions[data.title[0]]"
        @hoverOverBar="hoverRow(index)"
        :ref="`row${index}`"
      />
    </div>

    <div class="legend-container">
      <img
        src="@/assets/images/pointer-arrow-left.png"
        alt="Poiter Arrow"
        ref="pointerArrow"
        style="margin-right: -1px;">
      <div class="legend-text-container">
        <p>{{ informationText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ChartRow from './ChartRow.vue';

export default {
  name: 'Chart',
  props: {
    riasocCounts: {
      type: Map,
      required: true,
    },
    testName: {
      type: String,
      required: true,
    },
  },
  methods: {
    hoverRow(dataIndex) {
      const riasocType = this.chartData[dataIndex].title[0];
      this.informationText = this.groupDescriptions[riasocType];
      const arrow = this.$refs.pointerArrow;
      arrow.style.top = `${60 * dataIndex + 10}px`;
    },
    compareRiasocScore(a, b) {
      if (a.value > b.value) {
        return -1;
      }
      return 1;
    },
  },
  data() {
    return {
      chartData: [],
      riasocTitles: {
        R: 'Realist',
        I: 'Inhoudelijk',
        A: 'Artistiek',
        S: 'Sociaal',
        O: 'Ondernemend',
        C: 'Conventioneel',
      },
      kiesreisGroupDescriptions: {
        A: 'Als artistiek type gebruik jij je handen en je hoofd om dingen te creëren. Je hebt veel creatieve ideeën en vindt het heerlijk om deze op artistieke wijze uit te werken. Je hebt een rijke fantasie die je graag gebruikt om te schrijven, ontwerpen, vormgeven, filmen of bijvoorbeeld muziek te maken. Daarom past een tussenjaar waarin je creatief bezig bent goed bij jouw talenten! Er zijn genoeg mogelijkheden om jouw artistieke kant tijdens je gap year te gebruiken om nog meer van jezelf te laten zien!',
        O: 'Als ondernemend type zoek jij het avontuur op in je tussenjaar! Jij staat voorop wanneer er een nieuw project aangepakt moet worden. Jij bent goed in het ‘managen’ van dingen. Wanneer je iets gedaan moet krijgen, kan je andere mensen overtuigen van jouw plan. Daarom is werkervaring opdoen of een bedrijfje opzetten een super invulling van jouw tussenjaar. Maar daar blijft het niet bij, want stilzitten is voor jou geen optie. Als ondernemend type zoek jij in jouw tussenjaar veel actie en afwisseling!',
        S: 'Als sociaal type heb jij een sterke voorkeur om activiteiten met en voor mensen te doen. Je krijgt positieve energie van helpen, luisteren, zorgen en met mensen zijn. Je maakt makkelijk vrienden en vindt het belangrijk dat iedereen zich goed voelt in een groep. In jouw tussenjaar is het doen van iets als vrijwilligerswerk, au-pair zijn of samen met vrienden ervaringen opdoen op jouw lijf geschreven! Wat je ook doet, zolang je een steentje kan bijdragen voelt het voor jou goed!',
        R: 'Als realistisch type houd je ervan om je handen uit de mouwen te steken. Door middel van doen in plaats van denken ontdek jij de wereld om je heen. Je vindt het leuk om fysiek aan de slag te gaan en in de buitenlucht te zijn. Daarom is het een must voor jou om in je tussenjaar activiteiten af te wisselen, denkend aan sporten, bouwen, kamperen, bergwandelen of met dieren werken. Een nuttig tussenjaar houdt voor jou in aan de slag, doen, de natuur in en bewegen!',
        I: 'Als inhoudelijk type houdt ervan om dingen om je heen te onderzoeken door er veel over te leren, te lezen, te kijken of te luisteren. Zolang jij de kennis maar verkrijgt waar je naar op zoek bent! Daarom zal studeren in jouw tussenjaar jou aanspreken. Denk aan een heel jaar, half jaar of een paar maanden. Kies een onderwerp uit waar je meer over wilt weten en combineer dit met een gave stad die je wilt ontdekken. Op die manier heb je twee vliegen in een klap; een gave ervaring en een hoop nieuwe kennis!',
        C: 'Als conventioneel type houd jij ervan om goed voorbereid te zijn! Je wilt alles tot in de puntjes goed georganiseerd hebben. Zo wil je jouw tussenjaar van te voren goed doordacht hebben, en zal je op zoek gaan naar mensen die hun ervaringen met je delen. Zekerheid en stabiliteit is in jouw tussenjaar belangrijk. Bijvoorbeeld een taal leren of met een organisatie mee waar alles goed geregeld is, past perfect bij jouw persoonlijkheid. Tijdens jouw tussenjaar kom jij niet voor onverwachte verrassingen te staan!',
      },
      generalGroupDescriptions: {
        A: 'Als artistiek type gebruik jij je handen en je hoofd om dingen te creëren. Je hebt veel ideeën en vindt het heerlijk om deze op artistieke wijze uit te werken. Je hebt een rijke fantasie die je graag gebruikt om te schrijven, ontwerpen, vormgeven, filmen of bijvoorbeeld muziek te maken. Daarom past een opleiding waarin je creatief bezig bent goed bij jouw talenten! ',
        O: 'Als ondernemend type zoek jij een studie met avontuur. Jij staat voorop wanneer er een nieuw project aangepakt moet worden. Jij bent goed in het ‘managen’ van dingen. Wanneer je iets gedaan moet krijgen, kan je andere mensen overtuigen van jouw plan. Stilzitten is voor jou geen optie, als ondernemend type zoek jij in jouw opleiding veel actie en afwisseling!',
        S: 'Als sociaal type heb jij een sterke voorkeur om activiteiten met en voor mensen te doen. Je krijgt positieve energie van helpen, luisteren, zorgen, adviseren en met mensen zijn. Je maakt makkelijk vrienden en vindt het belangrijk dat iedereen zich goed voelt in een groep. In jouw opleiding is het belangrijk dat je iets kan betekenen voor de medemens en de maatschappij.  Wat je ook doet, zolang je een steentje kan bijdragen voelt het voor jou goed!',
        R: 'Als realistisch type houd je ervan om je handen uit de mouwen te steken. Door middel van doen in plaats van alleen maar denken, ontdek jij de wereld om je heen. Je vindt het leuk om fysiek aan de slag te gaan en/of jouw technische inzicht in te zetten. Voor jou is zichtbaar resultaat belangrijk.',
        I: 'Als inhoudelijk type houd je ervan om dingen om je heen te onderzoeken door er veel over te leren, te lezen, te kijken of te luisteren. Zolang jij de kennis maar verkrijgt waar je naar op zoek bent! Een opleiding waar je mag onderzoeken, verdiepen en je kennis kan vergroten, zal bij jou passen.',
        C: 'Als conventioneel type houd jij ervan om goed voorbereid te zijn! Je wilt alles tot in de puntjes  georganiseerd hebben. Zo wil je jouw opleidingskeuze van tevoren goed doordacht hebben, en zal je op zoek gaan naar mensen die hun ervaringen met je delen. Zekerheid en stabiliteit is voor jou belangrijk. Je houdt van controle, orde, structuur, regels en betrouwbaarheid.',
      },
      informationText: '',
      maxScore: 0.0,
    };
  },
  mounted() {
    const scores = [];
    const rawScores = [];

    this.riasocCounts.forEach((value, key) => {
      scores.push({
        title: this.riasocTitles[key],
        value,
      });
      rawScores.push(value);
    });

    this.maxScore = Math.max(...rawScores);
    this.chartData = scores;
    this.informationText = this.groupDescriptions[scores[0].title[0]];
  },
  components: {
    ChartRow,
  },
  computed: {
    groupDescriptions() {
      if (this.testName === 'riasoc_kiesreis') {
        return this.kiesreisGroupDescriptions;
      }
      return this.generalGroupDescriptions;
    },
  },
};
</script>

<style lang="scss">
@import '../../../assets/scss/main.scss';

.chart-legend-container {
  @include flex-row(center);
}

.legend-container {
  margin-left: .5em;
  @include md-max {
    display: none;
  }
  @include lg {
    @include flex-row(center);
    flex-grow: 1;
  }

  & img {
    height: $chart-bar-height;
    position: relative;
    top: 20px;
    -webkit-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
    width: $chart-standard-padding;
  }

  & .legend-text-container {
    @include flex-column(center);
    border: 8px solid $orange;
    border-radius: 10px;
    height: 360px;
    max-width: 250px;
    margin: 0;
    padding: $chart-standard-padding;
  }

  & p {
    font-size: .8em;
  }
}
</style>
