<template>
  <div class="m-5" v-if="testAnswers">
    <div class="w-100 mt-2 d-flex justify-content-center">
      <button
        v-if="!pdfLoading"
        class="blue-button w-100 mt-2"
        @click="generateReport()">
        Downloaden (PDF)
      </button>
      <moon-loader v-if="pdfLoading" color="#F26000"></moon-loader>
    </div>

  <h5
    class="mt-4"
    v-if="selectedAnswersAndQuestion.length > 0 && notFittingAnswersAndQuestion.length > 0"
  >
    Waardenspel Antwoorden
  </h5>
    <h6>Jouw {{selectedAnswersAndQuestion.length}} Geselecteerde Waarden</h6>
    <!-- {{selectedAnswersAndQuestion}} -->
    <waarden-cards-view
      :allCards="selectedAnswersAndQuestion"
    />

    <h6>{{notFittingAnswersAndQuestion.length}} waarden die totaal niet bij jou passen</h6>
    <waarden-cards-view
      :allCards="notFittingAnswersAndQuestion"
    />
  </div>
</template>

<script>
// import { snap } from '@interactjs/modifiers/snap/pointer';
import MoonLoader from 'vue-spinner/src/MoonLoader.vue';
import WaardenCardsView from '@/components/waardenDetails/WaardenCardsView.vue';
import { mapGetters } from 'vuex';
import { QuestionAPI, TestTranslator, PDFGeneration } from '../../../../Shared/index';

export default {
  name: 'PersonalWaardenAnswers',
  props: ['testIdentifier'],
  data: () => {
    return {
      selectedAnswersAndQuestion: [],
      notFittingAnswersAndQuestion: [],
      notSelectedAnswersAndQuestion: [],
      testAnswers: null,
      pdfLoading: false,
    };
  },
  methods: {
    generateReport() {
      this.pdfLoading = true;
      PDFGeneration.generateWaardenReport(
        this.selectedAnswersAndQuestion,
        this.notFittingAnswersAndQuestion,
        `${this.userInformation.firstName} ${this.userInformation.lastName}`,
      )
        .then(() => {
          this.pdfLoading = false;
        });
    },
    questionTypeToTestName(testName) {
      return TestTranslator.questionTypeToTestName(testName);
    },
    async createMetricsForChart() {
      const { allAnswers } = this.testAnswers.data;

      const snapshot = await QuestionAPI.getAll(this.testIdentifier);

      this.notFittingAnswersAndQuestion = this.combineAnswersAndQuestions(
        'not_fitting',
        snapshot,
        allAnswers,
      );

      this.notSelectedAnswersAndQuestion = this.combineAnswersAndQuestions(
        'not_selected',
        snapshot,
        allAnswers,
      );

      this.selectedAnswersAndQuestion = this.combineAnswersAndQuestions(
        'selected',
        snapshot,
        allAnswers,
      );
    },
    combineAnswersAndQuestions(type, questionsSnapshot, allAnswers) {
      const answerAndQuestionIdMap = new Map();
      allAnswers[type].forEach((answer) => {
        answerAndQuestionIdMap.set(answer.qid, answer);
      });

      const allQuestionsAndAnswers = [];
      questionsSnapshot.forEach((doc) => {
        doc.data().questions.forEach((question) => {
          // If the question is inactive or we do not have an answer for it, skip it
          if (!question.questionActive || !answerAndQuestionIdMap.get(question.id)) return;
          allQuestionsAndAnswers.push({
            question,
            answer: answerAndQuestionIdMap.get(question.id),
          });
        });
      });

      return allQuestionsAndAnswers;
    },
  },
  components: {
    WaardenCardsView,
    MoonLoader,
  },
  mounted() {
    this.testAnswers = this.$store.getters['answers/allAnswers'].get(this.testIdentifier);
    this.createMetricsForChart();
  },
  computed: {
    ...mapGetters({
      userInformation: 'user/userInformation',
    }),
  },
};
</script>
