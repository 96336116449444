<template>
  <b-container class="question-row">
    <b-row align-v="center">
      <b-col cols="8" class="text-left"><strong>{{ question.question }}</strong></b-col>
      <b-col cols="2">
        <div class="riasoc-bubble d-flex justify-content-center align-items-center">
          {{ question.riasocType }}
        </div>
      </b-col>
      <b-col cols="2">
        <button class="edit-button" @click="openModal()">
          <b-icon-pencil-fill icon="type-bold" class="mr-2" variant="primary"></b-icon-pencil-fill>
        </button>
      </b-col>
    </b-row>

    <b-modal :id="`riasoc-edit-question-modal-${this.question.id}`"
      title="Pas deze vraag aan" hide-footer>
      <EditRiasocQuestion :questionSetId="questionsSetId" :question="question"></EditRiasocQuestion>
    </b-modal>
  </b-container>
</template>

<script>
import EditRiasocQuestion from '@/components/editQuestions/EditRiasocQuestion.vue';

export default {
  name: 'RiasocQuestionRow',
  props: ['question', 'questionsSetId'],
  methods: {
    openModal() {
      this.$bvModal.show(`riasoc-edit-question-modal-${this.question.id}`);
    },
  },
  components: {
    EditRiasocQuestion,
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/main.scss';

.riasoc-bubble {
  background-color: $orange;
  color: white;
  width: 50px;
  height: 50px;
  font-weight: bolder;
  font-size: 1.6em;
  border-radius: 50%;
}
</style>
