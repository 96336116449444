<template>
  <div class="">
    <div class="waarden-cards-list">
      <WaardenCard
        v-for="card in allCards"
        :key="card.id"
        :waarde="card"
        :isSelected="false"
        @cardInspectClicked="inspectCard"
        :ref="'bubble-' + card.question.id"
      />
    </div>

    <!-- Image Enlargement -->
    <img
      ref="floatingBubble"
      class="image-bubble floating-bubble hidden"
      alt="Waarde Kaart"
    />
    <div class="bubble-explanation-overlay hidden scene" ref="overlay">
      <div class="waarde-card" ref="card" @click="rotateCard">
        <img
          class="large-image-bubble waarde-card__face"
          ref="overlayFrontImage"
          alt="Waarde Kaart"
        />
        <img
          class="large-image-bubble waarde-card__face waarde-card__back_face"
          ref="overlayBackImage"
          alt="Waarde Kaart"
        />
      </div>

      <div class="overlay-button-container">
        <button class="overlay-button" @click="rotateCard">
          Draaien
        </button>
        <button class="overlay-button" @click="closeOverlay">
          Sluiten
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import WaardenCard from './WaardenCard.vue';

export default {
  name: 'WaardenCardsView',
  props: {
    allCards: {
      type: Array,
      required: true,
    },
  },
  components: {
    WaardenCard,
  },
  methods: {
    inspectCard(card) {
      this.showBubbleDetail(card);
    },
    showBubbleDetail(card) {
      const bubbleId = card.question.id;

      const { floatingBubble } = this.$refs;

      let originBubble = this.$refs[`bubble-${bubbleId}`][0];
      // Select the image
      // eslint-disable-next-line prefer-destructuring
      originBubble = originBubble.$refs[`bubble-image-${bubbleId}`];
      const { overlay } = this.$refs;

      this.currentClickedBubble = originBubble;
      this.setDetailImages(card);

      const rect = originBubble.getBoundingClientRect();

      const borderWidth = 4.8; // 0.3em
      floatingBubble.style.left = `${rect.left + borderWidth}px`;
      floatingBubble.style.top = `${rect.top + borderWidth}px`;

      floatingBubble.classList.remove('hidden');

      let targetWidthHeight = 0.0;
      if (window.innerWidth <= 767) {
        // in the case of a mobile device the card has a width/height of 80vw
        targetWidthHeight = 0.8 * window.innerWidth;
      } else {
        // in the case of a 'large' device, the card has a width/heigh of 70vh
        targetWidthHeight = 0.7 * window.innerHeight;
      }

      const currentWidthHeight = floatingBubble.clientHeight;
      const scaleFactor = targetWidthHeight / currentWidthHeight;
      const overlayButtonContainerHeight = 45;

      const screenCenterX = (window.innerWidth / 2) - (currentWidthHeight / 2);
      const screenCenterY = (window.innerHeight / 2) - (currentWidthHeight / 2)
                   - overlayButtonContainerHeight;

      setTimeout(() => {
        floatingBubble.style.left = `${screenCenterX}px`;
        floatingBubble.style.top = `${screenCenterY}px`;
        floatingBubble.style.transform = `scale(${scaleFactor})`;
        setTimeout(() => {
          overlay.classList.remove('hidden');
          setTimeout(() => {
            overlay.style.opacity = 1.0;
            setTimeout(() => {
              floatingBubble.style.opacity = 0.0;
              floatingBubble.classList.add('hidden');
            }, 400);
          }, 10);
        }, 500);
      }, 0);
    },
    setDetailImages(card) {
      const { floatingBubble, overlayFrontImage, overlayBackImage } = this.$refs;
      floatingBubble.src = card.question.frontImage;
      overlayFrontImage.src = card.question.frontImage;
      overlayBackImage.src = card.question.backImage;
    },
    closeOverlay() {
      const targetBubble = this.currentClickedBubble;
      const { floatingBubble, overlay } = this.$refs;

      this.currentClickedBubble = null;

      const rect = targetBubble.getBoundingClientRect();

      let rotationDelay = 0;
      if (this.isRotated) {
        this.rotateCard();
        rotationDelay = 1000;
      }

      setTimeout(() => {
        floatingBubble.classList.remove('hidden');
        floatingBubble.style.opacity = 1.0;
        overlay.style.opacity = 0.0;

        setTimeout(() => {
          overlay.classList.add('hidden');

          const borderWidth = 4.8; // 0.3em
          floatingBubble.style.left = `${(rect.left + borderWidth)}px`;
          floatingBubble.style.top = `${(rect.top + borderWidth)}px`;
          floatingBubble.style.transform = 'scale(1.0)';

          setTimeout(() => {
            floatingBubble.classList.add('hidden');
          }, 500);
        }, 400);
      }, rotationDelay);
    },
    rotateCard() {
      this.$refs.card.classList.toggle('is-flipped');
      this.isRotated = !this.isRotated;
    },

    randomizeOffset(key) {
      const topOffset = Math.floor(Math.random() * 51) - 25;
      const leftOffset = Math.floor(Math.random() * 51) - 25;

      const element = this.$refs[key][0].$el;
      // eslint-disable-next-line no-param-reassign
      element.style.transform = `translate(${topOffset}px, ${leftOffset}px)`;
    },
  },
  mounted() {
    setTimeout(() => {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.$refs) {
        if (key.includes('bubble-')) {
          this.randomizeOffset(key);
        }
      }
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.cards-container {
  @include flex-row(flext-start)
}

.image-bubble {
  width: 12em;
  height: 12em;

  border-radius: 50%;
  // margin: 20px;
  cursor: pointer;
  transform: scale(0.0);

  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    @include shadow(0, 1px, 15px, 2px, rgba(0, 0, 0, 0.15));
  }
}

.bubble-explanation-overlay {
  @include flex-column(center);
  transition: opacity 0.4s;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  max-width: none;
  background-color: rgba(white, 0.5);
  opacity: 0.0;
  z-index: 100;
}

.overlay-button-container {
  @include flex-row(center);
  margin: 20px;
  max-width: none;
  & .overlay-button {
    @include basicButton;
    @include shadow(0, 1px, 15px, 2px, rgba(0, 0, 0, 0.15));
    align-self: center;
    border: 1px solid rgba(gray, 0.2);
    background-color: white;
    border-radius: 25px;
    font-size: 13px;
    width: 150px;
    height: 50px;
    margin: 0 5px;
  }
}

.floating-bubble {
  @include shadow(0, 1px, 15px, 2px, rgba(0, 0, 0, 0.15));
  transform: scale(1.0);
  position: fixed;
  transition: transform .5s ease-in-out, left .5s ease-in-out, top .5s ease-in-out, opacity .4s;
  z-index: 90;
}

.hidden {
  display: none;
}

.large-image-bubble {
  @include shadow(0, 1px, 15px, 2px, rgba(0, 0, 0, 0.15));
  @include sm-max {
    width: 80vw;
    height: 80vw;
  }
  @include md {
    width: 70vh;
    height: 70vh;
  }
  border-radius: 50%;
  border: none;
}

.scene {
  width: 100%;
  height: 100%;
  perspective: 600px;
}

.waarde-card {
  align-self: center;
  @include sm-max {
    width: 80vw;
    height: 80vw;
  }
  @include md {
    width: 70vh;
    height: 70vh;
  }
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
}

.waarde-card.is-flipped {
  transform: rotateY(180deg);
}

.waarde-card__face {
  position: absolute;
  left: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.waarde-card__back_face {
  transform: rotateY(180deg);
}

.waarden-cards-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 2em;
}

</style>
