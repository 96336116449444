<template>
  <b-form @submit.stop.prevent="onSubmit">
    <!-- <b-form-group>
      <b-form-radio-group
        v-model="form.accountType"
        :options="accountTypeOptions"
        button-variant="secondary"
        buttons
        class="w-100"
      ></b-form-radio-group>
    </b-form-group> -->

    <b-form-group>
      <b-form-input
        name="firstName"
        v-model="$v.form.firstName.$model"
        :state="validateState('firstName')"
        aria-describedby="firstName-feedback"
        placeholder="Voornaam"
        autocomplete="given-name"
        type="text"
      ></b-form-input>

      <b-form-invalid-feedback id="firstName-feedback">
        Dit is een verplicht veld
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group>
      <b-form-input
        name="lastName"
        v-model="$v.form.lastName.$model"
        :state="validateState('lastName')"
        aria-describedby="lastName-feedback"
        placeholder="Achternaam"
        type="text"
        autocomplete="family-name"
      ></b-form-input>

      <b-form-invalid-feedback id="lastName-feedback">
        Dit is een verplicht veld
      </b-form-invalid-feedback>
    </b-form-group>

    <div>
      <hr/>
      <Parent
        ref="parentForm"
        :childKieswijsCodes="userInformation.childKieswijsCodes"
        v-if="this.form.accountType === 'parent'"/>
      <Student
        ref="studentForm"
        :currentEducation="userInformation.currentEducation"
        :educationYear="userInformation.educationYear"
        :schoolName="userInformation.schoolName"
        :className="userInformation.className"
        v-if="this.form.accountType === 'student'"/>
    </div>

    <b-form-group v-if="userIsPasswordAuthenticated">
      <b-form-input
        name="email"
        v-model="$v.form.email.$model"
        :state="validateState('email')"
        aria-describedby="email-feedback"
        placeholder="Email"
        type="email"
      ></b-form-input>

      <b-form-invalid-feedback id="educationYear-feedback">
        Dit is een verplicht veld en moet een email bevatten
      </b-form-invalid-feedback>
    </b-form-group>

    <button class="blue-button w-100" type="submit">
      Opslaan
    </button>
  </b-form>
</template>

<script>
/* eslint-disable no-param-reassign */
import {
  required, email,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

import { auth } from '../../../../Shared/lib/firebase';
import { UserDataAPI } from '../../../../Shared/index';

import Parent from './EditComponents/Parent.vue';
import Student from './EditComponents/Student.vue';

export default {
  name: 'UpdateUserInformation',
  data: () => {
    return {
      userIsPasswordAuthenticated: false,
      // accountTypeOptions: [
      //   { text: 'Scholier/Student', value: 'student' },
      //   { text: 'Ouder/Verzorger', value: 'parent' },
      // ],
      form: {
        userid: null,
        firstName: null,
        lastName: null,
        accountType: 'student',
        email: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      data: 'user/data',
      userInformation: 'user/userInformation',
      userInformationDocumentId: 'user/userInformationDocumentId',
    }),
  },
  created() {
    this.form.userid = this.userInformation.uid;
    this.form.firstName = this.userInformation.firstName;
    this.form.lastName = this.userInformation.lastName;
    this.form.accountType = this.userInformation.accountType;
    this.form.email = this.data.email;
  },
  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        email,
        required,
      },
      className: { },
    },
  },
  mounted() {
    let userIsPasswordAuthenticated = false;
    this.data.providerData.forEach((data) => {
      if (data.providerId === 'password') {
        userIsPasswordAuthenticated = true;
      }
    });
    this.userIsPasswordAuthenticated = userIsPasswordAuthenticated;
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.showNotFilledInToast();
      } else {
        // Check additional information
        if (this.form.accountType === 'student') {
          const studentData = this.$refs.studentForm.getDataIfValidated();

          // Show a message if it is not filled in correctly
          if (studentData === null) {
            this.showNotFilledInToast();
            return;
          }
          this.uploadUserData(studentData);
          return;
        // eslint-disable-next-line no-else-return
        } else if (this.form.accountType === 'parent') {
          const parentData = this.$refs.parentForm.getDataIfValidated();

          // Show a message if it is not filled in correctly
          if (parentData === null) {
            this.showNotFilledInToast();
            return;
          }
          this.uploadUserData(parentData);
          return;
        }

        this.showNotFilledInToast();
      }
    },
    showNotFilledInToast() {
      this.$bvToast.toast('Niet alle velden zijn correct ingevuld', {
        toaster: 'b-toaster-bottom-full',
        autoHideDelay: 5000,
        variant: 'danger',
        solid: true,
        appendToast: false,
      });
    },
    checkFirstName(updateObject) {
      // Check first name
      if (this.form.firstName !== this.userInformation.firstName) {
        updateObject.firstName = this.form.firstName;
        updateObject.firstNameLowercase = this.form.firstName.toLowerCase();

        const user = auth.currentUser;
        user.updateProfile({
          displayName: `${this.form.firstName.trim()} ${this.form.lastName.trim()}`,
        }).then(() => {
          console.log('Display Name changed');
        });
      }
    },
    checkLastName(updateObject) {
      // Check last name
      if (this.form.lastName !== this.userInformation.lastName) {
        updateObject.lastName = this.form.lastName;
        updateObject.lastNameLowercase = this.form.lastName.toLowerCase();

        const user = auth.currentUser;
        user.updateProfile({
          displayName: `${this.form.firstName.trim()} ${this.form.lastName.trim()}`,
        }).then(() => {
          console.log('Display Name changed');
        });
      }
    },
    checkAccountTypeParent(updateObject) {
      if (this.form.accountType !== this.userInformation.accountType) {
        console.log('Account type changed to parent');
        updateObject.accountType = this.form.accountType;

        // Empty out all student data
        updateObject.currentEducation = null;
        updateObject.educationYear = null;
        updateObject.schoolName = null;
        updateObject.className = null;
      }
    },
    checkChildKieswijsCodes(updateObject, additionalData) {
      if (additionalData.childKieswijsCodes !== this.userInformation.childKieswijsCodes) {
        updateObject.childKieswijsCodes = additionalData.childKieswijsCodes;
      }
    },
    checkAccountTypeStudent(updateObject) {
      if (this.form.accountType !== this.userInformation.accountType) {
        console.log('Account type changed to student');
        updateObject.accountType = this.form.accountType;

        // Empty out all student data
        updateObject.childKieswijsCodes = null;
      }
    },
    checkCurrentEducation(updateObject, additionalData) {
      if (additionalData.currentEducation !== this.userInformation.currentEducation) {
        updateObject.currentEducation = additionalData.currentEducation;

        // If it concerns higher education, we remove the class name
        if (['vmbo', 'havo', 'vwo'].indexOf(additionalData.currentEducation) === -1) {
          updateObject.className = '';
        }
      }
    },
    checkEducationYear(updateObject, additionalData) {
      if (additionalData.educationYear !== this.userInformation.educationYear) {
        updateObject.educationYear = additionalData.educationYear;
      }
    },
    checkSchoolName(updateObject, additionalData) {
      if (additionalData.schoolName !== this.userInformation.schoolName) {
        updateObject.schoolName = additionalData.schoolName.toLowerCase();
      }
    },
    checkClassName(updateObject, additionalData) {
      if (additionalData.className !== this.userInformation.className) {
        updateObject.className = additionalData.className.toLowerCase();
      }
    },
    checkEmail() {
      if (this.form.email !== this.data.email) {
        auth.currentUser.updateEmail(this.form.email);
        this.$store.commit('user/UPDATE_EMAIL', this.form.email);
      }
    },
    uploadUserData(additionalData) {
      const userInfoUpdateMap = {};
      this.checkFirstName(userInfoUpdateMap);
      this.checkLastName(userInfoUpdateMap);
      if (this.form.accountType === 'parent') {
        this.checkAccountTypeParent(userInfoUpdateMap);
        this.checkChildKieswijsCodes(userInfoUpdateMap, additionalData);
      } else if (this.form.accountType === 'student') {
        this.checkAccountTypeStudent(userInfoUpdateMap);
        this.checkCurrentEducation(userInfoUpdateMap, additionalData);
        this.checkEducationYear(userInfoUpdateMap, additionalData);
        this.checkSchoolName(userInfoUpdateMap, additionalData);
        this.checkClassName(userInfoUpdateMap, additionalData);
      }

      // Update the info
      if (Object.keys(userInfoUpdateMap).length > 0) {
        UserDataAPI.update(this.userInformationDocumentId, userInfoUpdateMap)
          .then(() => {
            this.$store.dispatch('user/loadUserData');
          });
      }

      // Finally, we check the email
      if (this.userIsPasswordAuthenticated) {
        this.checkEmail();
      }

      this.$bvModal.hide('change-user-info');
    },
  },
  components: {
    Parent,
    Student,
  },
};
</script>
