<template>
  <div class="my-5">
    <div v-if="$router.history.current.name === 'UserInformation' && currentUser">
      <h5>
        <span v-if="loggedInUserId === currentUser.uid">(Jij)</span>
        {{`${currentUser.firstName} ${currentUser.lastName}`}}
      </h5>

      <h6 v-if="currentUser.accountType === 'parent'">
        Ouder/Verzorger
      </h6>

      <b-container class="py-3">
        <b-row
          align-v="center"
          align-h="center"
          v-if="loggedInUserId !== currentUser.uid && permissions.isSuperuser">
          <b-col cols="9">
            <button
              class="orange-button m-1"
              @click="removeRights()">
              Alle rechten weghalen
            </button>
            <button
              class="orange-button m-1"
              @click="makeStaff()">
              Maak staff
            </button>
            <button
              class="orange-button m-1"
              @click="makeEditor()">
              Maak editor
            </button>
            <button
              class="orange-button m-1"
              @click="makeSuperuser()">
              Maak superuser
            </button>
          </b-col>
        </b-row>
      </b-container>

      <PerformedTestOverview
        :uid="currentUser.uid"
        @selectedTest="selectedTest($event)"
        :showsHidden="true"
        :loadParentAnswers="true"/>
      <hr/>

      <b-container>
        <b-row align-h="center">
          <b-col cols="5" class="blue-card">
            <router-link :to="{ name: 'UploadTestPDF' }"
              class="w-100"
              style="color: white; width: 100%; height: 100%;">
              <b-icon-file-earmark-arrow-up/> Nieuwe uitslag (PDF) uploaden
            </router-link>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <router-view/>
  </div>
</template>

<script>
import PerformedTestOverview from '@/components/viewUsers/PerformedTestOverview.vue';
import { mapGetters } from 'vuex';

import { UserManagementAPI } from '../../../../../Shared/index';

export default {
  name: 'UserInformatiomn',
  components: {
    PerformedTestOverview,
  },
  methods: {
    selectedTest(testIdentifier) {
      let routeName;
      if (testIdentifier.includes('riasoc')) {
        routeName = 'RiasocAnswers';
      } else if (testIdentifier.includes('identiteit')) {
        routeName = 'IdentiteitAnswers';
      } else if (testIdentifier.includes('_test_answers')) {
        routeName = 'TestAnswers';
      } else if (testIdentifier.includes('waardenspel')) {
        routeName = 'WaardenAnswers';
      }

      this.$router.push({
        name: routeName,
        params: {
          testIdentifier,
        },
      });
    },
    removeRights() {
      UserManagementAPI.alterUserPermissions(
        this.currentUser.uid,
        true,
        false,
        false,
        false,
      ).then(() => {
        this.showChangedToast();
      });
    },
    makeStaff() {
      UserManagementAPI.alterUserPermissions(
        this.currentUser.uid,
        false,
        true,
        false,
        false,
      ).then(() => {
        this.showChangedToast();
      });
    },
    makeEditor() {
      UserManagementAPI.alterUserPermissions(
        this.currentUser.uid,
        false,
        false,
        true,
        false,
      ).then(() => {
        this.showChangedToast();
      });
    },
    makeSuperuser() {
      UserManagementAPI.alterUserPermissions(
        this.currentUser.uid,
        false,
        false,
        false,
        true,
      ).then(() => {
        this.showChangedToast();
      });
    },
    showChangedToast() {
      this.$bvToast.toast('De gebruiker is aangepast', {
        toaster: 'b-toaster-bottom-full',
        autoHideDelay: 5000,
        variant: 'info',
        solid: true,
        appendToast: false,
      });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'answers/user',
      permissions: 'user/permissions',
      loggedInUserId: 'user/id',
    }),
  },
};
</script>
