<template>
  <transition name="fade">
    <div
      v-if="isLoading"
      class="d-flex flex-column justify-content-center align-items-center">
      <moon-loader color="#F26000"></moon-loader>
      <p class="orange-small-label mt-2">Aan het laden...</p>
    </div>

    <div class="m-5" v-if="testAnswers && !isLoading">
      <button class="no-border blue-card w-75" @click="downloadResults()">
        Download jouw resultaten!
      </button>
    </div>
  </transition>
</template>

<script>

import MoonLoader from 'vue-spinner/src/MoonLoader.vue';
import { mapGetters } from 'vuex';
import { FileAPI } from '../../../../Shared/index';

export default {
  name: 'PersonalTestAnswers',
  props: ['testIdentifier'],
  data: () => {
    return {
      testAnswers: null,
      isLoading: false,
    };
  },
  methods: {
    downloadResults() {
      this.isLoading = true;

      const testName = this.testIdentifier.replace('_test_answers', '');

      FileAPI.listFiles(`resultaten-pdfs/${this.currentUserId}/${testName}`)
        .then((results) => {
          results.items.forEach((itemRef) => {
            this.downloadFile({
              name: itemRef.name,
              path: `resultaten-pdfs/${this.currentUserId}/${testName}/${itemRef.name}`,
            });
          });
          this.isLoading = false;
        })
        .catch((error) => {
          console.log('Error', error);
          this.isLoading = false;
        });
    },
    downloadFile(file) {
      FileAPI.downloadFile(file.path)
        .then((url) => {
          const link = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          link.download = 'resultaat.pdf';
          link.dispatchEvent(new MouseEvent('click'));
          this.isLoading = false;
        })
        .catch((error) => {
          console.log('Error', error);
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.testAnswers = this.$store.getters['answers/allAnswers'].get(this.testIdentifier);
  },
  computed: {
    ...mapGetters({
      currentUserId: 'user/id',
    }),
  },
  components: {
    MoonLoader,
  },
};
</script>
