<template>
  <div class="m-5 d-flex flex-column align-items-center" v-if="correctTestAnswers">
    <div class="d-flex justify-content-between align-items-center visibility-bar w-100">
      <div class="d-flex align-items-center">
        <div
          class="availability-indicator"
          :class="{
            'is-available': correctTestAnswers.data.isVisibleForUser,
            'is-unavailable': !correctTestAnswers.data.isVisibleForUser
            }"></div>
        <span class="m-0 d-none d-md-inline">Beschikbaar voor kandidaat.</span>
      </div>

      <button
        class="orange-button"
        v-if="!correctTestAnswers.data.isVisibleForUser"
        @click="changeAvailability(true)">
        Maak nu beschikbaar
      </button>
      <button
        class="orange-button"
        v-if="correctTestAnswers.data.isVisibleForUser"
        @click="changeAvailability(false)">
        Maak onbeschikbaar
      </button>
    </div>

    <div class="w-100 mt-2 d-flex justify-content-center">
      <button
        v-if="!pdfLoading"
        class="blue-button w-100 mt-2"
        @click="generateReport()">
        Downloaden (PDF)
      </button>
      <moon-loader v-if="pdfLoading" color="#F26000"></moon-loader>
    </div>

    <h5 class="mt-5">Snel Overzicht</h5>
    <Chart
      v-if="riasocCounts !== null"
      :riasocCounts="riasocCounts"
      :testName="testIdentifier.replace('_parent', '')"
      class="mb-5"/>

    <h5 class="mt-4">Alle antwoorden</h5>
    <RiasocAnswerRow
      v-for="qAndA in allQuestionsAndAnswers"
      :key="qAndA.question.id"
      :questionAndAnswer="qAndA"/>

    <div
      class="w-100 mt-2 d-flex justify-content-center"
      v-if="permissions.isSuperuser">
       <button
        class="red-button w-100 mt-2"
        @click="deleteResult()">
        Verwijderen
      </button>
    </div>
  </div>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue';

import Chart from '@/components/riasocDetails/chart/Chart.vue';
import RiasocAnswerRow from '@/components/riasocDetails/RiasocAnswerRow.vue';

import { mapGetters } from 'vuex';

import {
  QuestionAPI, AnswerAPI, PDFGeneration, TestTranslator,
} from '../../../../../../Shared/index';

export default {
  name: 'RiasocAnswers',
  props: ['testIdentifier'],
  data: () => {
    return {
      allQuestionsAndAnswers: null,
      riasocCounts: null,
      correctTestAnswers: null,
      pdfLoading: false,
    };
  },
  methods: {
    generateReport() {
      this.pdfLoading = true;
      PDFGeneration.generateRIASOCReport(this.riasocCounts, `${this.currentUser.firstName} ${this.currentUser.lastName}`)
        .then(() => {
          this.pdfLoading = false;
        });
    },
    changeAvailability(newAvailability) {
      const { testIdentifier } = this;
      this.$store.commit('answers/CHANGE_ANSWER_STATUS', {
        testIdentifier,
        newAvailability,
      });
      AnswerAPI.updateQuestionStatus(this.correctTestAnswers.docId, newAvailability);
      this.correctTestAnswers = this.$store.getters['answers/allAnswers'].get(this.testIdentifier);
    },
    questionTypeToTestName(testName) {
      return TestTranslator.questionTypeToTestName(testName);
    },
    async createMetricsForChart() {
      const answersForTest = this.correctTestAnswers.data;
      const answerAndQuestionIdMap = new Map();
      answersForTest.allAnswers.forEach((answer) => {
        answerAndQuestionIdMap.set(answer.qid, answer);
      });

      const questions = new Map();

      const snapshot = await QuestionAPI.getAll(
        this.testIdentifier.replace('_parent', ''),
      );

      this.allQuestionsAndAnswers = [];
      snapshot.forEach((doc) => {
        doc.data().questions.forEach((question) => {
          // If the question is inactive or we do not have an answer for it, skip it
          if (!question.questionActive || !answerAndQuestionIdMap.get(question.id)) return;

          questions.set(question.id, question);
          this.allQuestionsAndAnswers.push({
            question,
            answer: answerAndQuestionIdMap.get(question.id),
          });
        });
      });

      // Sort on riasocType
      this.allQuestionsAndAnswers.sort((a, b) => {
        if (a.question.riasocType > b.question.riasocType) {
          return 1;
        // eslint-disable-next-line no-else-return
        } else if (a.question.riasocType < b.question.riasocType) {
          return -1;
        }
        return 0;
      });

      const trueTypes = [];
      answersForTest.allAnswers.forEach((answer) => {
        if (answer.answer) {
          try {
            trueTypes.push(questions.get(answer.qid).riasocType);
          } catch (err) {
            console.log(err);
          }
        }
      });

      let scoreMap = new Map([
        ['R', 0],
        ['I', 0],
        ['A', 0],
        ['S', 0],
        ['O', 0],
        ['C', 0],
      ]);

      trueTypes.forEach((categoryId) => {
        scoreMap.set(categoryId, scoreMap.get(categoryId) + 1);
      });

      scoreMap = new Map([...scoreMap.entries()].sort((a, b) => {
        if (a[1] < b[1]) return 1;
        return -1;
      }));

      this.riasocCounts = scoreMap;
    },
    deleteResult() {
      const { testIdentifier } = this;
      AnswerAPI.remove(this.correctTestAnswers.docId)
        .then(() => {
          this.$store.commit('answers/DELETE_ANSWER', {
            testIdentifier,
          });
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    Chart,
    RiasocAnswerRow,
    MoonLoader,
  },
  mounted() {
    this.correctTestAnswers = this.$store.getters['answers/allAnswers'].get(this.testIdentifier);
    this.createMetricsForChart();
  },
  computed: {
    ...mapGetters({
      currentUser: 'answers/user',
      permissions: 'user/permissions',
    }),
  },
};
</script>
