<template>
  <div class="w-100 px-5 py-2 d-flex justify-content-between align-items-center">
    <router-link to="/">
      <img alt="Kieswijs logo" src="../assets/images/Kieswijs-logo-web.png" class="navbar-logo">
    </router-link>
    <transition name="fade">
      <button class="orange-button px-3" @click="signOut()" v-if="userData">
        Uitloggen
      </button>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Authentication } from '../../../Shared/index';

export default {
  name: 'NavigationBar',
  methods: {
    signOut() {
      Authentication.signOut()
        .then(() => {
          if (this.$router.currentRoute.name !== 'Home') {
            this.$router.push({ name: 'Home' });
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      userData: 'user/data',
    }),
  },
};
</script>

<style lang="scss" scoped>
.navbar-logo {
  height: 40px;
  width: auto;
}
</style>
