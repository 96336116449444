<template>
  <transition name="fade">
    <div class="home">
      <div v-if="userInformation && !isLoading">
        <div v-if="permissions
          && (permissions.isStaff || permissions.isEditor || permissions.isSuperuser)">
          <EmployeeBar
          />
          <div
            class="m-1">
          </div>
        </div>

        <b-container class="py-3" v-if="$router.history.current.name !== 'Home'">
          <b-row align-v="center" align-h="center">
            <b-col cols="3">
              <button class="blue-button px-3" @click="$router.go(-1)">
                <b-icon-chevron-left/> Terug
              </button>
            </b-col>
            <b-col cols="6">
              <h3>Jouw Dashboard</h3>
            </b-col>
            <b-col cols="3"></b-col>
          </b-row>
        </b-container>

        <div v-if="$router.history.current.name === 'Home'">
          <h4 class="mt-5">Bekijk hieronder al jouw laatste resultaten</h4>
          <PerformedTestOverview
              :uid="currentUserId"
              @selectedTest="selectedTest($event)"
              :showsHidden="false"
              :loadParentAnswers="false"/>

          <h4 class="mt-5">Jouw informatie</h4>
          <UserPersonalInformation/>
        </div>

        <router-view/>
      </div>

      <div class="my-5" v-if="!userInformation && !isLoading">
        <h3>Welkom op Mijn Kieswijs</h3>
        <p>Klik op de knop hieronder om in te loggen of een nieuw account aan te maken</p>
        <button class="orange-button w-75 my-3" @click="signIn()">Inloggen</button>
      </div>

      <div
        v-if="isLoading"
        class="mt-5 d-flex flex-column justify-content-center align-items-center">
        <moon-loader v-if="isLoading" color="#F26000"></moon-loader>
        <p class="orange-small-label mt-2">Aan het laden...</p>
      </div>
    </div>
  </transition>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue';
import { mapGetters } from 'vuex';
import EmployeeBar from '@/components/EmployeeBar.vue';
import PerformedTestOverview from '@/components/viewUsers/PerformedTestOverview.vue';
import UserPersonalInformation from '@/components/viewUsers/UserPersonalInformation.vue';
import { Authentication } from '../../../Shared/index';

export default {
  name: 'Home',
  data: () => {
    return {
      isLoading: false,
    };
  },
  components: {
    EmployeeBar,
    MoonLoader,
    PerformedTestOverview,
    UserPersonalInformation,
  },
  computed: {
    ...mapGetters({
      permissions: 'user/permissions',
      userInformation: 'user/userInformation',
      currentUserId: 'user/id',
    }),
  },
  methods: {
    signIn() {
      this.isLoading = true;
      Authentication.authenticateIfRequired()
        .then(() => {
          console.log('THEN');
          if (this.$router.currentRoute.name !== 'Home') {
            this.$router.push({ name: 'Home' });
          }

          // The authentication might take some time, so we wait with removing
          // the loading screen
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        })
        .catch((error) => {
          console.log('ERRRO', error);
          this.isLoading = false;
        });
    },
    selectedTest(testIdentifier) {
      let routeName;
      if (testIdentifier.includes('riasoc')) {
        routeName = 'PersonalRiasocAnswers';
      } else if (testIdentifier.includes('identiteit')) {
        routeName = 'PersonalIdentiteitAnswers';
      } else if (testIdentifier.includes('waardenspel')) {
        routeName = 'PersonalWaardenAnswers';
      } else if (testIdentifier.includes('_test_answers')) {
        routeName = 'PersonalTestAnswers';
      }

      this.$router.push({
        name: routeName,
        params: {
          testIdentifier,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/main.scss';

.link-to-other-app {
  @include flex-column(center);
  border: 4px solid black;
  width: 200px !important;
  height: 200px !important;
  background-color: white;
  & img {
    width: 80%;
    align-self: center;
    // height: 50%;
  }
}

.yellow-border { border-color: $yellow; }
.blue-border { border-color: $blue; }
.red-border { border-color: $red; }
.green-border { border-color: $green; }
</style>
