<template>
  <div class="global-header d-flex flex-column justify-content-center align-items-start">
    <transition name="fade">
      <div class="text-left image-overlay-box" v-if="userInformation">
        <h5>Welkom</h5>
        <h2 v-if="userInformation">
          {{ userInformation.firstName }} {{ userInformation.lastName }}
        </h2>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'GlobalHeader',
  data: () => {
    return {
      currentUserName: null,
    };
  },
  computed: {
    ...mapGetters({
      userInformation: 'user/userInformation',
    }),
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';

.image-overlay-box {
  background: rgba(white, 0.7);
  padding: 2em;
}
.global-header {
  padding: 1em;
  @include md {
    padding: 100px;
  }

  background-image: url('../assets/images/Kieswijs-homeslide-kieswijsanalyse.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100vw;
  height: 50vh;
}
</style>
