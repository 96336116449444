<template>
  <b-container fluid class="question-row">
    <b-row align-v="center" align-h="center">
      <b-col cols="9" class="text-left">
        <strong>{{ questionAndAnswer.question.question }}</strong>
      </b-col>
      <b-col cols="3">
        <b-icon-check-circle class="h1 green" v-if="questionAndAnswer.answer.answer"/>
        <b-icon-x-circle class="h1 red" v-if="!questionAndAnswer.answer.answer"/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'IdentiteitAnswerRow',
  props: ['questionAndAnswer'],
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/main.scss';

.green {
  color: $green !important;
}
.red {
  color: $red !important;
}
</style>
