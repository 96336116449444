<template>
  <div>
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group>
        <b-form-input
          name="question"
          v-model="$v.form.question.$model"
          :state="validateState('question')"
          aria-describedby="question-feedback"
          placeholder="Vraag"
          type="text"
        ></b-form-input>

        <b-form-invalid-feedback id="question-feedback">
          Dit is een verplicht veld
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- RIASOC TYPE -->
      <b-form-group>
        <b-form-select
          name="riasocType"
          v-model="$v.form.riasocType.$model"
          :options="riasocTypeOptions"
          :state="validateState('riasocType')"
          aria-describedby="riasocType"
        ></b-form-select>

        <b-form-invalid-feedback id="riasocType-feedback">
          Dit is een verplicht veld
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- Active -->
      <b-form-group>
        <p>{{$v.form.questionActive.$mosdel}}</p>
        <b-form-checkbox
          name="questionActive"
          v-model="$v.form.questionActive.$model"
          :state="validateState('questionActive')"
          aria-describedby="questionActive-feedback"
          :value="true"
          :unchecked-value="false"
        >
          Actief
        </b-form-checkbox>

        <b-form-invalid-feedback id="questionActive-feedback">
          Je moet akkoord gaan met de voorwaarden om een account aan te maken
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- Image -->
      <a v-if="form.imageUrl" :href="form.imageUrl" target="_blank">
        Bekijk Afbeelding >
      </a>
      <ImageUploadField
        @imageUploaded="uploadedImage"
        uploadPath="riasoc"></ImageUploadField>

      <!-- Image ALT -->
      <b-form-group>
        <b-form-input
          name="imageAlt"
          v-model="$v.form.imageAlt.$model"
          :state="validateState('imageAlt')"
          aria-describedby="imageAlt-feedback"
          placeholder="Afbeelding (Alternatieve tekst)"
          type="text"
        ></b-form-input>

        <b-form-invalid-feedback id="imageAlt-feedback">
          Dit is een verplicht veld
        </b-form-invalid-feedback>
      </b-form-group>

      <button class="blue-button w-100" type="submit">
        Opslaan
      </button>
    </b-form>
  </div>
</template>

<script>
import {
  required,
} from 'vuelidate/lib/validators';
import ImageUploadField from '@/components/general/ImageUploadField.vue';
import { v4 as uuidv4 } from 'uuid';
import { RiasocQuestion, QuestionAPI } from '../../../../Shared/index';

export default {
  name: 'SignUp',
  props: {
    questionSetId: {
      type: String,
      required: true,
    },
    question: {
      type: Object,
      required: false,
    },
  },
  data: () => {
    return {
      riasocTypeOptions: [
        {
          value: null,
          text: 'RIASOC type',
        },
        {
          value: 'R',
          text: 'R',
        },
        {
          value: 'I',
          text: 'I',
        },
        {
          value: 'A',
          text: 'A',
        },
        {
          value: 'S',
          text: 'S',
        },
        {
          value: 'O',
          text: 'O',
        },
        {
          value: 'C',
          text: 'C',
        },
      ],
      form: {
        question: null,
        riasocType: null,
        questionActive: null,
        imageUrl: null,
        imageAlt: null,
      },
      isEditingExistingQuestion: false,
    };
  },
  created() {
    if (this.question) {
      this.isEditingExistingQuestion = true;
      this.form.question = this.question.question;
      this.form.riasocType = this.question.riasocType;
      this.form.questionActive = this.question.questionActive;
      this.form.imageUrl = this.question.imageUrl;
      this.form.imageAlt = this.question.imageAlt;
    }
  },
  validations: {
    form: {
      question: {
        required,
      },
      riasocType: {
        required,
      },
      imageAlt: {
        required,
      },
      imageUrl: { },
      questionActive: { },
    },
  },
  methods: {
    uploadedImage(data) {
      this.form.imageUrl = data;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$bvToast.toast('Niet alle velden zijn correct ingevuld', {
          toaster: 'b-toaster-bottom-full',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          appendToast: false,
        });
      } else if (this.form.imageUrl === undefined) {
        this.$bvToast.toast('Er is geen afbeelding toegevoegd', {
          toaster: 'b-toaster-bottom-full',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          appendToast: false,
        });
      } else {
        if (this.isEditingExistingQuestion) {
          this.updateOld();
          return;
        }
        this.uploadNew();
      }
    },
    uploadNew() {
      QuestionAPI.addQuestion(this.questionSetId, new RiasocQuestion(
        uuidv4(),
        this.form.question,
        this.form.riasocType,
        this.form.questionActive,
        this.form.imageUrl,
        this.form.imageAlt,
      ), 'riasoc').then(() => {
        this.$bvModal.hide('new-question-modal');
      })
        .catch((error) => {
          console.log(`[add question] ${error}`);
        });
    },
    updateOld() {
      const updatedQuestion = new RiasocQuestion(
        this.question.id,
        this.form.question,
        this.form.riasocType,
        this.form.questionActive,
        this.form.imageUrl,
        this.form.imageAlt,
      );

      // Remove old
      QuestionAPI.removeQuestion(this.questionSetId, this.question, 'riasoc')
        .then(() => {
          // Add new
          QuestionAPI.addQuestion(this.questionSetId, updatedQuestion, 'riasoc').then(() => {
            this.$bvModal.hide(`riasoc-edit-question-modal-${this.question.id}`);
          })
            .catch((error) => {
              console.log(`[add question update] Error: ${error}`);
            });
        })
        .catch((error) => {
          console.log(`[remove question update] Error: ${error}`);
        });
    },
  },
  components: {
    ImageUploadField,
  },
};
</script>
