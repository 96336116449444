<template>
  <b-container class="question-row">
    <b-row align-v="center">
      <b-col cols="11" class="text-left"><strong>{{ question.question }}</strong></b-col>
      <b-col cols="1">
        <button class="edit-button" @click="openModal()">
          <b-icon-pencil-fill icon="type-bold" class="mr-2" variant="primary"></b-icon-pencil-fill>
        </button>
      </b-col>
    </b-row>

    <b-modal
      :id="`two-sided-question-edit-question-modal-${this.question.id}`"
      title="Pas deze vraag aan"
      hide-footer>
      <EditTwoSidedCardQuestion
        :questionSetId="questionsSetId"
        :question="question"
        :questionTypeName="questionTypeName"></EditTwoSidedCardQuestion>
    </b-modal>
  </b-container>
</template>

<script>
import EditTwoSidedCardQuestion from '@/components/editQuestions/EditTwoSidedCardQuestion.vue';

export default {
  name: 'TwoSidedQuestionRow',
  props: {
    question: {
      type: Object,
      required: true,
    },
    questionsSetId: {
      type: String,
      required: true,
    },
    questionTypeName: {
      type: String,
      required: false,
    },
  },
  methods: {
    openModal() {
      this.$bvModal.show(`two-sided-question-edit-question-modal-${this.question.id}`);
    },
  },
  components: {
    EditTwoSidedCardQuestion,
  },
};
</script>
