<template>
  <div>
    <b-form @submit.stop.prevent="onSubmit">
      <b-form-group>
        <b-form-input
          name="question"
          v-model="$v.form.question.$model"
          :state="validateState('question')"
          aria-describedby="question-feedback"
          placeholder="Vraag"
          type="text"
        ></b-form-input>

        <b-form-invalid-feedback id="question-feedback">
          Dit is een verplicht veld
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- Active -->
      <b-form-group>
        <p>{{$v.form.questionActive.$mosdel}}</p>
        <b-form-checkbox
          name="questionActive"
          v-model="$v.form.questionActive.$model"
          :state="validateState('questionActive')"
          aria-describedby="questionActive-feedback"
          :value="true"
          :unchecked-value="false"
        >
          Actief
        </b-form-checkbox>

        <b-form-invalid-feedback id="questionActive-feedback">
          Je moet akkoord gaan met de voorwaarden om een account aan te maken
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- Image -->
      <p><strong>Voorkant:</strong></p>
      <a v-if="form.frontImage" :href="form.frontImage" target="_blank">
        Bekijk Afbeelding >
      </a>
      <ImageUploadField
        @imageUploaded="uploadedFrontImage"
        :uploadPath="`${questionTypeName}/front`"></ImageUploadField>

      <!-- Image ALT -->
      <b-form-group>
        <b-form-input
          name="frontImageAlt"
          v-model="$v.form.frontImageAlt.$model"
          :state="validateState('frontImageAlt')"
          aria-describedby="frontImageAlt-feedback"
          placeholder="Voorkant Afbeelding (Alternatieve tekst)"
          type="text"
        ></b-form-input>
        <b-form-invalid-feedback id="frontImageAlt-feedback">
          Dit is een verplicht veld
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- Image -->
      <p><strong>Achterkant:</strong></p>
      <a v-if="form.backImage" :href="form.backImage" target="_blank">
        Bekijk Afbeelding >
      </a>
      <ImageUploadField
        @imageUploaded="uploadedBackImage"
        :uploadPath="`${questionTypeName}/back`"></ImageUploadField>

      <!-- Image ALT -->
      <b-form-group>
        <b-form-input
          name="backImageAlt"
          v-model="$v.form.backImageAlt.$model"
          :state="validateState('backImageAlt')"
          aria-describedby="backImageAlt-feedback"
          placeholder="Achterkant Afbeelding (Alternatieve tekst)"
          type="text"
        ></b-form-input>
        <b-form-invalid-feedback id="backImageAlt-feedback">
          Dit is een verplicht veld
        </b-form-invalid-feedback>
      </b-form-group>

      <button class="blue-button w-100" type="submit">
        Opslaan
      </button>
    </b-form>
  </div>
</template>

<script>
import {
  required,
} from 'vuelidate/lib/validators';
import ImageUploadField from '@/components/general/ImageUploadField.vue';
import { v4 as uuidv4 } from 'uuid';
import { TwoSidedImageCardQuestion, QuestionAPI } from '../../../../Shared/index';

export default {
  name: 'EditTwoSidedCardQuestion',
  props: {
    questionSetId: {
      type: String,
      required: true,
    },
    questionTypeName: {
      type: String,
      required: true,
    },
    question: {
      type: Object,
      required: false,
    },
  },
  data: () => {
    return {
      form: {
        question: null,
        questionActive: null,
        frontImage: null,
        frontImageAlt: null,
        backImage: null,
        backImageAlt: null,
      },
      isEditingExistingQuestion: false,
    };
  },
  created() {
    if (this.question) {
      this.isEditingExistingQuestion = true;
      this.form.question = this.question.question;
      this.form.questionActive = this.question.questionActive;
      this.form.frontImage = this.question.frontImage;
      this.form.frontImageAlt = this.question.frontImageAlt;
      this.form.backImage = this.question.backImage;
      this.form.backImageAlt = this.question.backImageAlt;
    }
  },
  validations: {
    form: {
      question: {
        required,
      },
      frontImageAlt: {
        required,
      },
      frontImage: { },
      backImageAlt: {
        required,
      },
      backImage: { },
      questionActive: { },
    },
  },
  methods: {
    uploadedFrontImage(data) {
      this.form.frontImage = data;
    },
    uploadedBackImage(data) {
      this.form.backImage = data;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$bvToast.toast('Niet alle velden zijn correct ingevuld', {
          toaster: 'b-toaster-bottom-full',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          appendToast: false,
        });
      } else if (this.form.frontImage === undefined) {
        this.$bvToast.toast('Er is geen voorkant afbeelding toegevoegd', {
          toaster: 'b-toaster-bottom-full',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          appendToast: false,
        });
      } else if (this.form.backImage === undefined) {
        this.$bvToast.toast('Er is geen achterkant afbeelding toegevoegd', {
          toaster: 'b-toaster-bottom-full',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          appendToast: false,
        });
      } else {
        if (this.isEditingExistingQuestion) {
          this.updateOld();
          return;
        }
        this.uploadNew();
      }
    },
    uploadNew() {
      QuestionAPI.addQuestion(this.questionSetId, new TwoSidedImageCardQuestion(
        uuidv4(),
        this.form.question,
        this.form.questionActive,
        this.form.frontImage,
        this.form.frontImageAlt,
        this.form.backImage,
        this.form.backImageAlt,
      ), this.questionTypeName).then(() => {
        this.$bvModal.hide('new-question-modal');
      })
        .catch((error) => {
          console.log(`[add question] ${error}`);
        });
    },
    updateOld() {
      const updatedQuestion = new TwoSidedImageCardQuestion(
        this.question.id,
        this.form.question,
        this.form.questionActive,
        this.form.frontImage,
        this.form.frontImageAlt,
        this.form.backImage,
        this.form.backImageAlt,
      );

      // Remove old
      QuestionAPI.removeQuestion(this.questionSetId, this.question, this.questionTypeName)
        .then(() => {
          // Add new
          QuestionAPI.addQuestion(
            this.questionSetId,
            updatedQuestion,
            this.questionTypeName,
          ).then(() => {
            this.$bvModal.hide(`two-sided-question-edit-question-modal-${this.question.id}`);
          })
            .catch((error) => {
              console.log(`[add question update] Error: ${error}`);
            });
        })
        .catch((error) => {
          console.log(`[remove question update] Error: ${error}`);
        });
    },
  },
  components: {
    ImageUploadField,
  },
};
</script>
