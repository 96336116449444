<template>
  <div class="m-5">
    <transition name="fade">
      <div
        v-if="isLoading"
        class="d-flex flex-column justify-content-center align-items-center">
        <moon-loader color="#F26000"></moon-loader>
        <p class="orange-small-label mt-2">Aan het laden...</p>
      </div>

      <b-form @submit.stop.prevent="onSubmit" v-if="!isLoading">
        <b-form-group>
          <b-form-select
            name="testName"
            v-model="$v.form.testName.$model"
            :options="testNameOptions"
            :state="validateState('testName')"
            aria-describedby="testName"
            @change="checkCurrentFiles($event)"
          ></b-form-select>

          <b-form-invalid-feedback id="testName-feedback">
            Dit is een verplicht veld
          </b-form-invalid-feedback>
        </b-form-group>

        <div v-if="currentFiles.length === 0 && this.form.testName !== null">
          <b-form-group>
            <b-form-file
              name="file"
              v-model="$v.form.file.$model"
              :state="validateState('file')"
              accept="application/pdf"
              aria-describedby="file"
              placeholder="Kies een bestand of sleep deze hier naartoe..."
              drop-placeholder="Sleep bestand hier naartoe..."
            ></b-form-file>

            <b-form-invalid-feedback id="file-feedback">
              Dit is een verplicht veld
            </b-form-invalid-feedback>
          </b-form-group>

          <button class="blue-button w-100" type="submit">
            <b-icon-file-earmark-arrow-up/> Uploaden
          </button>
        </div>

        <div v-if="currentFiles.length > 0"  class="text-left">
          <p><strong>Huidige bestanden:</strong></p>
          <b-container fluid class="question-row" v-for="file in currentFiles" :key="file.path">
            <b-row align-v="center" align-h="center">
              <b-col cols="9">
                <strong>{{ file.name }}</strong>
              </b-col>
              <b-col cols="3" class="text-right">
                <button
                  class="text-only-button p-3"
                  type="button"
                  @click="downloadFile(file)"
                  style="background-color: transparent; font-size: 1.3em;">
                  <b-icon-file-earmark-arrow-down/>
                </button>
                <button
                  class="text-only-button p-3"
                  type="button"
                  @click="deleteFile(file)"
                  style="background-color: transparent; font-size: 1.3em;">
                  <b-icon-trash/>
                </button>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-form>
    </transition>

    <b-modal id="are-you-sure-to-delete-modal" hide-footer>
      <template #modal-title>
        Weet je zeker dat je dit bestand wilt verwijderen?
      </template>
      <button class="mr-2 red-button" type="button" @click="confirmDelete()">Verwijderen</button>
      <button class="gray-button" type="button" @click="cancelDelete()">Annuleren</button>
    </b-modal>
  </div>
</template>

<script>
import MoonLoader from 'vue-spinner/src/MoonLoader.vue';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { storage, firebaseStorage } from '../../../../../Shared/lib/firebase';
import { FileAPI, AnswerAPI } from '../../../../../Shared/index';

export default {
  name: 'UploadTestPDF',
  data: () => {
    return {
      testNameOptions: [
        {
          value: null,
          text: 'Test',
        },
        {
          value: 'studiekeuze',
          text: 'Studiekeuze',
        },
        {
          value: 'kiesmaster',
          text: 'Kiesmaster',
        },
        {
          value: 'kieswerk',
          text: 'Kieswerk',
        },
      ],
      form: {
        testName: null,
        file: null,
      },
      isLoading: false,
      currentFiles: [],
      selectedFileToDelete: null,
    };
  },
  validations: {
    form: {
      testName: {
        required,
      },
      file: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$bvToast.toast('Niet alle velden zijn correct ingevuld', {
          toaster: 'b-toaster-bottom-full',
          autoHideDelay: 5000,
          variant: 'danger',
          solid: true,
          appendToast: false,
        });
      } else {
        this.uploadDocument();
      }
    },
    uploadDocument() {
      this.isLoading = true;
      const uploadTask = storage.ref()
        .child(`resultaten-pdfs/${this.currentUser.uid}/${this.form.testName}/${this.form.file.name}`)
        .put(this.form.file);

      uploadTask.on(firebaseStorage.TaskEvent.STATE_CHANGED, null,
        () => {
          this.$bvToast.toast('Er is iets fout gegaan tijdens het uploaden', {
            toaster: 'b-toaster-bottom-full',
            autoHideDelay: 5000,
            variant: 'danger',
            solid: true,
            appendToast: false,
          });
          this.isLoading = false;
        }, () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            AnswerAPI.create({
              all_answers: downloadURL,
              question_type: `${this.form.testName}_test_answers`,
              is_visible_for_user: true,
              user: this.currentUser.uid,
            }).then((docRef) => {
              // Add to store for immediate access in Test overview
              this.$store.commit('answers/ADD_SINGLE_ANSWER', {
                id: docRef.id,
                data: {
                  allAnswers: downloadURL,
                  questionType: `${this.form.testName}_test_answers`,
                  isVisibleForUser: true,
                  user: this.currentUser.uid,
                },
              });

              // Notify user of success
              this.isLoading = false;
              this.$bvToast.toast('Het bestand is geupload', {
                toaster: 'b-toaster-bottom-full',
                autoHideDelay: 5000,
                variant: 'success',
                solid: true,
                appendToast: false,
              });

              // Check for new files and show them
              this.checkCurrentFiles(this.form.testName);
            })
              .catch((error) => {
                console.log(`Error: ${error}`);
              });
          });
        });
    },
    checkCurrentFiles(event) {
      if (event === null) return;

      this.isLoading = true;
      this.currentFiles = [];
      FileAPI.listFiles(`resultaten-pdfs/${this.currentUser.uid}/${this.form.testName}`)
        .then((results) => {
          results.items.forEach((itemRef) => {
            this.currentFiles.push({
              name: itemRef.name,
              path: `resultaten-pdfs/${this.currentUser.uid}/${this.form.testName}/${itemRef.name}`,
            });
          });
          this.isLoading = false;
        })
        .catch((error) => {
          console.log('Error', error);
          this.isLoading = false;
        });
    },
    downloadFile(file) {
      this.isLoading = true;

      FileAPI.downloadFile(file.path)
        .then((url) => {
          const link = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          link.download = 'resultaat.pdf';
          link.dispatchEvent(new MouseEvent('click'));
          this.isLoading = false;
        })
        .catch((error) => {
          console.log('Error', error);
          this.isLoading = false;
        });
    },
    deleteFile(file) {
      this.selectedFileToDelete = file;
      this.$bvModal.show('are-you-sure-to-delete-modal');
    },
    confirmDelete() {
      const documentId = this.$store.getters['answers/allAnswers']
        .get(`${this.form.testName}_test_answers`)
        .docId;

      FileAPI.deleteFile(this.selectedFileToDelete.path)
        .then(() => {
          AnswerAPI.remove(documentId)
            .then(() => {
              this.checkCurrentFiles(this.form.testName);
              this.$store.commit(
                'answers/REMOVE_ANSWER_OF_TEST',
                `${this.form.testName}_test_answers`,
              );
              this.$bvModal.hide('are-you-sure-to-delete-modal');

              this.isLoading = false;
              this.selectedFileToDelete = null;
            })
            .catch((error) => {
              console.log(`Error: ${error}`);
              this.$bvModal.hide('are-you-sure-to-delete-modal');
              this.isLoading = false;
              this.selectedFileToDelete = null;
            });
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvModal.hide('are-you-sure-to-delete-modal');
          this.selectedFileToDelete = null;
        });
    },
    cancelDelete() {
      this.selectedFileToDelete = null;
      this.$bvModal.hide('are-you-sure-to-delete-modal');
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'answers/user',
    }),
  },
  components: {
    MoonLoader,
  },
};
</script>
