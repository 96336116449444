<template>
  <div class="p-5 employee-bar d-flex flex-column justify-content-center align-items-start
    text-left">
    <router-link to="medewerkers">
      Naar het medewerkersdashboard <b-icon-chevron-right icon="type-bold"></b-icon-chevron-right>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'GlobalHeader',
};
</script>

<style lang="scss">
@import '../assets/scss/main.scss';

.image-overlay-box {
  background: rgba(white, 0.7);
  padding: 2em;
}
.employee-bar {
  width: 100vw;
  background-color: $blue;
  & a {
    color: white;
    font-size: 1.3em;
    &:hover {
      color: white;
    }
  }
}
</style>
